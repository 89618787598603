import React, { useEffect } from 'react'

/**
 * getWindowDimensions this function write for getting windo width or height
 * @returns width and height
 */
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {
        width,
        height
    }
}
/**
 * useWindowDimensions is function write for crete a custom hook
 * @returns it returns what are the diementions of window
 */
export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions())

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return windowDimensions
}
