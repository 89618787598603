// BASE_URL is API's base url are get from .env file
const BASE_URL = process.env.REACT_APP_API_URL

/**
 * Common API END-POINTS
 * auth is object for creting all the authorization endpoints using during api call
 */
export const auth = Object.freeze({
    getUser: (emailId) => `${BASE_URL}/auth?email=${emailId}`
})

/**
 * Common API END-POINTS
 * customerSupport object create for all the customer support endpoints using API calls
 */
export const customerSupport = Object.freeze({
    raiseIssue: `${BASE_URL}/customer-support`,
    viewIssues: `${BASE_URL}/customer-support`,
    unreadNotification: `${BASE_URL}/customer-support/unread-notification`
})

/**
 * BUSINESS PARTNER / BRANCH ADMIN API END-POINTS
 * branch object create for all the branch API's endpoints using API calls
 */
export const branch = Object.freeze({
    addBranch: `${BASE_URL}/branch`,
    editBranch: `${BASE_URL}/branch`,
    viewBranch: `${BASE_URL}/branch/profile`,
    viewBranchKpi: `${BASE_URL}/queue/kpi`
})

/**
 * BUSINESS PARTNER / BRANCH ADMIN API END-POINTS
 * branchSubscription object create for all the branchSubscription API's endpoints using API calls
 */
export const branchSubscription = Object.freeze({
    getSubscriptions: `${BASE_URL}/subscription-package`,
    editSubscriptions: (id) => `${BASE_URL}/subscription-package/${id}`,
    getPackageHistory: (id = undefined, page = undefined, limit = undefined) => {
        let url = `${BASE_URL}/subscription-package/subscription/history`
        if (id && page && limit) {
            url += `?branchId=${id}&page=${page}&limit=${limit}`
        } else if (page && limit) {
            url += `?page=${page}&limit=${limit}`
        }
        return url
    },
    confirmSubscription: `${BASE_URL}/subscription-package/subscribe/confirm`,
    addSubscriptions: `${BASE_URL}/subscription-package`,
    paymentToken: `${BASE_URL}/payment-gateway/transaction/token`,
    paymentCancel: `${BASE_URL}/payment-gateway/transaction/cancel`
})

/**
 * Common API END-POINTS
 * notify object are create for all the notification API endpoints using API calls
 */
export const notify = Object.freeze({
    push_notification: `${BASE_URL}/notification/`,
    sms_notification: `${BASE_URL}/notification/send-by-sms`,
    whatsApp_notification: `${BASE_URL}/notification/send-by-whatsapp`,
    notificationAll: `${BASE_URL}/notification/send-all`,
    getNotifications: `${BASE_URL}/customer-support/history`
})

/**
 * BUSINESS PARTNER / BRANCH ADMIN API END-POINTS
 * queues object are create for all the queue API endpoints create using API calls
 */
export const queues = Object.freeze({
    viewQueues: (page, limit, search = undefined) => {
        let url = `${BASE_URL}/queue?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    addQueues: `${BASE_URL}/queue`,
    viewSingle: (id) => `${BASE_URL}/queue/${id}`,
    editQueue: (qId) => `${BASE_URL}/queue/${qId}`,
    deleteQueue: (id) => `${BASE_URL}/queue/${id}`,
    viewAssignQueues: (emailId) => `${BASE_URL}/queue-admin/${emailId}`,
    forFcmToken: `${BASE_URL}/queue-admin/`
})

/**
 * BUSINESS PARTNER / BRANCH ADMIN API END-POINTS
 * queueAdmin object are create for all the queueAdmin API endpoints create using API calls
 */
export const queueAdmin = Object.freeze({
    viewQueueAdmin: (page, limit, country_code = undefined, search = undefined) => {
        let url = `${BASE_URL}/queue-admin/find-all/?page=${page}&limit=${limit}`
        if (country_code && search) {
            url += `&country_code=${country_code}&search=${search}`
        } else if (search) {
            url += `&search=${search}`
        } else if (country_code) {
            url += `&country_code=${country_code}`
        }
        return url
    },
    singleQueueAdmin: (id) => `${BASE_URL}/queue-admin/${id}`,
    creteQueueAdmin: `${BASE_URL}/queue-admin`,
    editQueueAdmin: (emailId) => `${BASE_URL}/queue-admin/${emailId}`,
    deleteQueueAdmin: (id) => `${BASE_URL}/queue-admin/${id}`
})

/**
 * BUSINESS PARTNER / BRANCH ADMIN API END-POINTS
 * qList object are create for all the queue lists API endpoints create using API calls
 */
export const qList = Object.freeze({
    viewCurrent: (id, page, limit, search = undefined) => {
        let url = `${BASE_URL}/customer-booking-data/${id}?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    addToQueue: `${BASE_URL}/customer-booking-data`,
    moveOrRemove: (id) => `${BASE_URL}/customer-booking-data/${id}`,
    viewHistory: (id, page, limit, country_code = undefined, search = undefined, filter = undefined) => {
        let url = `${BASE_URL}/customer-booking-data/history/${id}?page=${page}&limit=${limit}`
        if (country_code && search) {
            url += `&country_code=${country_code}&search=${search}`
        } else if (search) {
            url += `&search=${search}`
        } else if (country_code) {
            url += `&country_code=${country_code}`
        }
        if (filter) {
            url += `&filter=${filter}`
        }
        return url
    },
    checkUser: (number) => `${BASE_URL}/customer/contact/${number}`
})

/**
 * CONCORDE ADMIN // SUPER ADMIN API END-POINTS
 * concordeAdmin object are create for all the concorde admin API endpoints create using API calls
 */
export const concordeAdmin = Object.freeze({
    createBusiness: `${BASE_URL}/business`,
    editBusiness: (id) => `${BASE_URL}/business/${id}`,
    fetchBusiness: (page, limit, search = undefined) => {
        let url = `${BASE_URL}/business?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    fetchCustomerOtps: (year, month) => {
        let url = `${BASE_URL}/customer/monthly-otp-report?year=${year}&month=${month}`
        return url
    },
    businessViewDetails: (id) => `${BASE_URL}/business/${id}`,
    actionsBusiness: (id) => `${BASE_URL}/business/${id}`,
    fetchBusinessPartners: (status, page, limit, country_code = undefined, search = undefined) => {
        let url = `${BASE_URL}/branch?status=${status}&page=${page}&limit=${limit}`
        if (country_code && search) {
            url += `&country_code=${country_code}&search=${search}`
        } else if (search) {
            url += `&search=${search}`
        } else if (country_code) {
            url += `&country_code=${country_code}`
        }
        return url
    },
    profilePending: (id) => `${BASE_URL}/branch/profile?id=${id}`,
    mapBusinesses: (id) => `${BASE_URL}/business/map/${id}`,
    queueList: (id, status, page, limit, country_code = undefined, search = undefined, filter = undefined) => {
        let url = `${BASE_URL}/branch/queue-details/${id}?status=${status}&page=${page}&limit=${limit}`
        if (country_code && search) {
            url += `&country_code=${country_code}&search=${search}`
        } else if (search) {
            url += `&search=${search}`
        } else if (country_code) {
            url += `&country_code=${country_code}`
        }
        if (filter) {
            url += `&filter=${filter}`
        }
        return url
    },
    approveCustomerQueue: (
        id,
        status,
        page,
        limit,
        country_code = undefined,
        search = undefined,
        filter = undefined
    ) => {
        let url = `${BASE_URL}/customer/queue-customers/${id}?status=${status}&page=${page}&limit=${limit}`
        if (country_code && search) {
            url += `&country_code=${country_code}&search=${search}`
        } else if (search) {
            url += `&search=${search}`
        } else if (country_code) {
            url += `&country_code=${country_code}`
        }
        if (filter) {
            url += `&filter=${filter}`
        }
        return url
    },
    queueDetailsKpi: (id) => `${BASE_URL}/queue/kpi/?id=${id}`,
    notificationCount: (page, limit, year, month, search = undefined) => {
        let url = `${BASE_URL}/notification/monthly-report?year=${year}&month=${month}&page=${page}&limit=${limit}`
        // if (country_code && search) {
        //     url += `&country_code=${country_code}&search=${search}`
        // } else
        if (search) {
            url += `&search=${search}`
        }
        //  else if (country_code) {
        //     url += `&country_code=${country_code}`
        // }
        return url
    },
    customerKpi: (id) => `${BASE_URL}/customer-booking-data/kpi/${id}`,
    approvedHold: (id) => `${BASE_URL}/branch/status/${id}`,
    freeTrail: `${BASE_URL}/subscription-package/offer/free-package`
})

/**
 * CONCORDE ADMIN // SUPER ADMIN API END-POINTS
 * concordeCustomer object are create for all the concorde admin all Customers admin API endpoints create using API calls
 */
export const concordeCustomer = Object.freeze({
    viewCustomers: (page, limit, country_code = undefined, search = undefined) => {
        let url = `${BASE_URL}/customer?page=${page}&limit=${limit}`
        if (country_code && search) {
            url += `&country_code=${country_code}&search=${search}`
        } else if (search) {
            url += `&search=${search}`
        } else if (country_code) {
            url += `&country_code=${country_code}`
        }
        return url
    },
    customerAction: (id) => `${BASE_URL}/customer/status/${id}`,
    customersProfile: (id, page, limit, search = undefined, filter = undefined) => {
        let url = `${BASE_URL}/customer/queue-details/${id}?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }

        if (filter) {
            url += `&filter=${filter}`
        }
        return url
    }
})

/**
 * CONCORDE ADMIN // SUPER ADMIN API END-POINTS
 * concordeCustomerSupport object are create for all the customer support for concorde admin API endpoints create using API calls
 */
export const concordeCustomerSupport = Object.freeze({
    customerSupportKpi: `${BASE_URL}/customer-support/kpi-customers`,
    customerOpenDetails: (status, page, limit, country_code = undefined, search = undefined) => {
        let url = `${BASE_URL}/queries/customers?status=${status}&page=${page}&limit=${limit}`
        if (country_code && search) {
            url += `&country_code=${country_code}&search=${search}`
        } else if (search) {
            url += `&search=${search}`
        } else if (country_code) {
            url += `&country_code=${country_code}`
        }
        return url
    },
    customerQueriesDetails: (id, status) => `${BASE_URL}/queries/customer/${id}?status=${status}`,
    businessPartnerKpi: `${BASE_URL}/customer-support/kpi-admins`,
    businessPartnerQueries: (status, page, limit, country_code = undefined, search = undefined) => {
        let url = `${BASE_URL}/queries/partners?status=${status}&page=${page}&limit=${limit}`
        if (country_code && search) {
            url += `&country_code=${country_code}&search=${search}`
        } else if (search) {
            url += `&search=${search}`
        } else if (country_code) {
            url += `&country_code=${country_code}`
        }
        return url
    },
    closeQuery: (id) => `${BASE_URL}/queries/${id}`,
    businessQueriesDetails: (id, status) => `${BASE_URL}/queries/partner/${id}?status=${status}`
})
