/**
 * this Object write for firebase configuration
 */

// //Sandbox staging ---->
// export const firebaseConfig = {
//     apiKey: 'AIzaSyDFTb_O5nKg-3gFTojhAEJ407CDTDax9tw',
//     authDomain: 'qplease-sandbox.firebaseapp.com',
//     databaseURL: 'https://qplease-sandbox-default-rtdb.firebaseio.com',
//     projectId: 'qplease-sandbox',
//     storageBucket: 'qplease-sandbox.appspot.com',
//     messagingSenderId: '296414901828',
//     appId: '1:296414901828:web:136e6ca2958296bfc22e11',
//     measurementId: 'G-KTB67SJBQ0'
// }

//Production----->
export const firebaseConfig = {
    apiKey: 'AIzaSyA-HxjWOEYtc_rV2iGWaDyLhbtRT55sZbE',
    authDomain: 'qplease-ea868.firebaseapp.com',
    databaseURL: 'https://qplease-ea868-default-rtdb.firebaseio.com',
    projectId: 'qplease-ea868',
    storageBucket: 'qplease-ea868.appspot.com',
    messagingSenderId: '569557717009',
    appId: '1:569557717009:web:07a37debf343144def21b0',
    measurementId: 'G-5VRJXDJG19'
}
