// getting combineReducers from redux for all are the reducers combined together
import { combineReducers } from 'redux'
import signUp from './signUp.reducer'
import queueParam from './businessQue.reducer'
import businessPartner from './businessPartner.reducer'
import QueueAdminR from './qAdmin.reducer'
/**
 * rootReducer is combined all the reducer
 */
const rootReducer = combineReducers({ signUp, queueParam, businessPartner, QueueAdminR })

export default rootReducer
