import React from 'react'
import PropTypes from 'prop-types'

import { Row, Col, Breadcrumb, Tooltip } from 'antd'
import SpinnerComp from '../../../spinner'
import SuperAdminStyle from '../../superAdmin.module.scss'
import PackageInner from './PackageInner'
import { Link } from 'react-router-dom'

Subscription.propTypes = {
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired
}
/**
 *Subscription page
 * @param {*} data state data getting from parent for all packages set data in card
 * @param {*} loading state loding getting from parent for loader
 * @returns jsx element subscription page
 */
export default function Subscription({ data, loading }) {
    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Tooltip placement="topLeft" title={'Go Back!'}>
                        <Link to="/partner/view-business-profile" state={{ key: 'subscription-details' }}>
                            View business profile
                        </Link>
                    </Tooltip>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Subscription packages</Breadcrumb.Item>
            </Breadcrumb>
            <div className={SuperAdminStyle.subscriptionHeading}>
                <h1>
                    Subscription <span>packages</span>
                </h1>
                <p>Choose a plan that works best for you and your business.</p>
            </div>
            <Row gutter={[16, 16]} justify="center" align="middle">
                {loading ? (
                    <SpinnerComp />
                ) : (
                    data?.map((item, index) => {
                        if (item?.packageName == 'Free trial') {
                            return
                        } else {
                            return (
                                <Col key={index} xxl={6} xl={8} lg={12} md={12} sm={24} xs={24}>
                                    <PackageInner item={item} />
                                </Col>
                            )
                        }
                    })
                )}
            </Row>
        </>
    )
}
