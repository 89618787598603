import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useParams, useNavigate } from 'react-router-dom'
import { Form, Row, Col, Input, Upload, Modal, Button, InputNumber } from 'antd'
import SuperAdminStyle from '../../superAdmin.module.scss'

const { TextArea } = Input
SuperAdminProfile.propTypes = {
    form: PropTypes.object.isRequired,
    onFinish: PropTypes.func.isRequired,
    countryCodeBefore: PropTypes.object.isRequired,
    codeBefore: PropTypes.object.isRequired,
    uploadButton: PropTypes.object.isRequired,
    uploadLogoButton: PropTypes.object.isRequired,
    fileList: PropTypes.array.isRequired,
    setFileList: PropTypes.func.isRequired,
    logoPic: PropTypes.array.isRequired,
    setLogoPic: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
}
export default function SuperAdminProfile({
    form,
    onFinish,
    uploadButton,
    uploadLogoButton,
    codeBefore,
    countryCodeBefore,
    fileList,
    logoPic,
    setFileList,
    setLogoPic,
    loading
}) {
    const { branchId } = useParams()
    const navigate = useNavigate()

    const [previewOpen, setPreviewOpen] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [previewTitle, setPreviewTitle] = useState('')
    const resetFunc = () => {
        if (branchId) {
            navigate('/partner/view-business-profile')
            form.resetFields()
        } else {
            navigate('/auth/sign-in')
            form.resetFields()
        }
    }

    const handleCancel = () => {
        setPreviewOpen(false)
        setPreviewImage('')
        setPreviewTitle('')
    }
    const handlePreview = async (file) => {
        if (file.url) {
            setPreviewImage(file.url || file.preview)
            setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
            setPreviewOpen(true)
        } else if (file.response.data) {
            setPreviewImage(file.response.data[0].Location || file.preview)
            setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
            setPreviewOpen(true)
        } else {
            console.log('sdsfsdf', file)
        }
    }

    return (
        <>
            <div className={SuperAdminStyle.heading}>
                <h3>Branch Admin profile</h3>
                <h4>Enter the details of the managing admin for this business</h4>
            </div>
            <Form onFinish={onFinish} layout="vertical" form={form}>
                <Row gutter={[16, 16]}>
                    <Col xl={6} lg={8} md={8} sm={24} xs={24}>
                        <Form.Item
                            label="Full name"
                            name="user_name"
                            rules={[
                                { required: true, message: 'Please enter full name' },
                                {
                                    pattern: new RegExp(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/),
                                    message: 'Please enter valid name'
                                }
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xl={6} lg={8} md={8} sm={24} xs={24}>
                        <Form.Item
                            name="user_mobile"
                            label="Enter contact number"
                            rules={[
                                {
                                    required: true,
                                    message: `Please enter contact number`
                                },
                                { pattern: new RegExp(/^\d{10,15}$/), message: 'Please enter valid contact number' }
                            ]}>
                            <InputNumber stringMode addonBefore={codeBefore} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col xl={6} lg={8} md={8} sm={24} xs={24}>
                        <Form.Item
                            name="user_email"
                            label="Enter email id"
                            rules={[
                                {
                                    required: true,
                                    message: `Please enter email id`
                                },
                                {
                                    pattern: new RegExp(
                                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    ),
                                    message: 'Please enter valid email id'
                                }
                            ]}>
                            <Input disabled={branchId ? true : false} />
                        </Form.Item>
                    </Col>
                    <Col xl={6} lg={8} md={8} sm={24} xs={24}>
                        <Form.Item
                            label="Job role"
                            name="job_role"
                            rules={[
                                { required: true, message: 'Please enter job role' },
                                {
                                    pattern: new RegExp(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/),
                                    message: 'Please enter valid job role'
                                }
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <div className={SuperAdminStyle.heading}>
                    <h3>{branchId ? 'Update ' : 'Create'} your branch business profile</h3>
                </div>
                <Row gutter={[16, 16]} align="middle">
                    <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                        <Upload
                            action={`${process.env.REACT_APP_IMAGE_UPLOAD_API_URL}`}
                            listType="picture-card"
                            className={SuperAdminStyle.uploadDiv}
                            fileList={logoPic}
                            maxCount={1}
                            onChange={(response) => {
                                setLogoPic(response.fileList)
                            }}
                            onPreview={handlePreview}
                            name="files"
                            accept="image/*"
                            // headers={{ Authorization: token }}
                        >
                            {logoPic.length > 0 ? null : uploadLogoButton}
                        </Upload>
                    </Col>
                    <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                        <Form.Item
                            label="Enter business branch name"
                            name="name"
                            rules={[
                                { required: true, message: 'Please enter business name' },
                                {
                                    pattern: new RegExp(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/),
                                    message: 'Please enter valid business name'
                                }
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                        <Form.Item
                            name="phone_number"
                            label="Enter business branch contact number"
                            rules={[
                                {
                                    required: true,
                                    message: `Please enter business contact number`
                                },
                                {
                                    pattern: new RegExp(/^\d{10,15}$/),
                                    message: 'Please enter valid business contact number'
                                }
                            ]}>
                            <InputNumber stringMode addonBefore={countryCodeBefore} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            name="email"
                            label="Enter business branch email id"
                            rules={[
                                {
                                    required: true,
                                    message: `Please enter business email id`
                                },
                                {
                                    pattern: new RegExp(
                                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    ),
                                    message: 'Please enter valid business email id'
                                }
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            label="Enter business branch address"
                            name="address"
                            rules={[
                                { required: true, message: 'Please enter business address' },
                                { pattern: new RegExp('[A-Za-z]+'), message: 'Please enter valid business address' }
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            label="Enter one line description"
                            name="description"
                            rules={[
                                { required: true, message: 'Please enter business description' },
                                { pattern: new RegExp('[A-Za-z]+'), message: 'Please enter valid business description' }
                            ]}>
                            <TextArea rows={1} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xl={8} lg={8} sm={24}>
                        <Form.Item
                            label="Enter Business website url"
                            name="website_link"
                            rules={[
                                {
                                    type: 'url',
                                    // pattern: new RegExp(
                                    //     /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
                                    // ),

                                    message: 'Please enter valid website url address'
                                }
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xl={16} lg={16} sm={24}>
                        <div className={SuperAdminStyle.uploadText}>
                            <h3>Upload photos</h3>
                        </div>
                        <Upload
                            action={`${process.env.REACT_APP_IMAGE_UPLOAD_API_URL}`}
                            listType="picture-card"
                            className={SuperAdminStyle.uploadDiv}
                            fileList={fileList}
                            onChange={(response) => {
                                setFileList(response.fileList)
                            }}
                            name="files"
                            accept="image/*"
                            onPreview={handlePreview}>
                            {fileList.length >= 8 ? null : uploadButton}
                        </Upload>
                    </Col>
                </Row>
                <Form.Item>
                    <Row gutter={[16, 16]} align="middle" justify="center" className={SuperAdminStyle.buttons}>
                        <Col xxl={6} xl={8} lg={10} md={10} sm={12} xs={24}>
                            <Button
                                type="primary"
                                onClick={() => resetFunc()}
                                ghost={true}
                                className={SuperAdminStyle.cancelBtn}>
                                Cancel
                            </Button>
                        </Col>
                        <Col xxl={6} xl={8} lg={10} md={10} sm={12} xs={24}>
                            <Button
                                loading={loading}
                                disabled={loading}
                                htmlType="submit"
                                type="primary"
                                className={SuperAdminStyle.saveBtn}>
                                {branchId ? 'Update ' : 'Save '} profile
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>

            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                    alt="example"
                    style={{
                        width: '100%'
                    }}
                    src={previewImage}
                />
            </Modal>
        </>
    )
}
