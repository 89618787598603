// BusinessQueAction, signUpActions  are the types for written in types.js
import { BusinessQueAction, signUpActions } from './../actions/types'

/**
 * initialState is object to define all are the initial states
 */
const initialState = {
    queueFormData: {
        parameter: [],
        action: [],
        notification: [],
        start_time: '',
        end_time: '',
        qBooked: 0
    },
    qAdminFormData: {
        country_code: '',
        email_id: '',
        name: '',
        phone_number: '',
        role: ''
    },
    updateParamTrueOrFalse: false,
    editId: null,
    addType: '',
    sidebarQueues: [],
    allTableQueues: {},
    QueuePagination: {
        current: 1,
        pageSize: 10,
        total: 0
    },
    branchDetails: {},
    notEditId: -1,
    notEditData: { notifyAt: '', message: '' },
    businessStatus: '',
    caseId: '',
    subsQueueDetails: {},
    isEditableQueue: false,
    subscriptionDetails: {},
    paymentModal: false,
    paymentDetails: {},
    paymentToken: '',
    isPaymentExp: false,
    expireStatus: 'ON_GOING',
    expireDetails: null
}

/**
 * queueParam function are the reducer function to return the what state is changes to check that and update that
 * @param {*} state state is the values of default initialState
 * @param {*} action action is the object have type and payload
 * @returns it returns eigther state what should update or default state
 */
function queueParam(state = initialState, action) {
    //checking action.type is sended type and set action.payload
    switch (action.type) {
        case BusinessQueAction.SET_ADD_QUEUE_FORM_DETAILS: {
            return Object.assign({}, state, {
                queueFormData: { ...state.queueFormData, ...action.payload }
            })
        }
        case BusinessQueAction.SET_O_ADMIN_FORM_DETAILS: {
            return Object.assign({}, state, {
                qAdminFormData: { ...state.qAdminFormData, ...action.payload }
            })
        }
        case BusinessQueAction.SET_QUEUE_PARAM_OR_ACTION: {
            return Object.assign({}, state, { addType: action.payload })
        }
        case BusinessQueAction.SET_QUEUE_PARAM_ADD: {
            const _queueFormData = { ...state.queueFormData }
            _queueFormData.parameter.push(action.payload)
            return Object.assign({}, state, { queueFormData: _queueFormData })
        }
        case BusinessQueAction.SET_QUEUE_PARAM_EDIT: {
            const _queueFormData = { ...state.queueFormData }
            _queueFormData.parameter = action.payload
            return Object.assign({}, state, { queueFormData: _queueFormData })
        }
        case BusinessQueAction.SET_QUEUE_PARAM_DELETE: {
            const _queueFormData = { ...state.queueFormData }
            _queueFormData.parameter.splice(action.payload, 1)
            return Object.assign({}, state, { queueFormData: _queueFormData })
        }
        case BusinessQueAction.SET_QUEUE_ACTION_ADD: {
            const _queueFormData = { ...state.queueFormData }
            _queueFormData.action.push(action.payload)
            return Object.assign({}, state, { queueFormData: _queueFormData })
        }
        case BusinessQueAction.SET_QUEUE_ACTION_EDIT: {
            const _queueFormData = { ...state.queueFormData }
            _queueFormData.action = action.payload
            return Object.assign({}, state, { queueFormData: _queueFormData })
        }
        case BusinessQueAction.SET_QUEUE_ACTION_DELETE: {
            const _queueFormData = { ...state.queueFormData }
            _queueFormData.action.splice(action.payload, 1)
            return Object.assign({}, state, { queueFormData: _queueFormData })
        }
        case BusinessQueAction.SET_QUEUE_NOTIFICATION_ADD: {
            const _queueFormData = { ...state.queueFormData }
            _queueFormData.notification.push(action.payload)
            return Object.assign({}, state, { queueFormData: _queueFormData })
        }
        case BusinessQueAction.SET_QUEUE_NOTIFICATION_EDIT: {
            const _queueFormData = { ...state.queueFormData }
            _queueFormData.notification = action.payload
            return Object.assign({}, state, { queueFormData: _queueFormData })
        }
        case BusinessQueAction.SET_QUEUE_NOTIFICATION_DELETE: {
            const _queueFormData = { ...state.queueFormData }
            _queueFormData.notification.splice(action.payload, 1)
            return Object.assign({}, state, { queueFormData: _queueFormData })
        }
        case BusinessQueAction.SET_CLEAR_QUEUE_FORM_DETAILS: {
            return Object.assign({}, state, {
                queueFormData: {
                    parameter: [],
                    action: [],
                    notification: []
                }
            })
        }
        case BusinessQueAction.SET_PARAM_TRUE_FALSE: {
            return Object.assign({}, state, { updateParamTrueOrFalse: action.payload })
        }
        case BusinessQueAction.SET_ALL_QUEUE_DETAILS: {
            let temp
            temp = action.payload
            if (temp && temp.data.length > 0) {
                temp = temp.data.filter((item) => item.queue_status === true)
            } else {
                temp = []
            }
            return Object.assign({}, state, { sidebarQueues: temp, allTableQueues: action.payload })
        }
        case BusinessQueAction.SET_QUEUE_PAGINATION: {
            return Object.assign({}, state, { QueuePagination: action.payload })
        }
        case BusinessQueAction.SET_EDIT_ID_FORM: {
            return Object.assign({}, state, { editId: action.payload })
        }
        case BusinessQueAction.SET_BRANCH_DETAILS: {
            return Object.assign({}, state, { branchDetails: action.payload })
        }
        case BusinessQueAction.SET_NOT_EDIT_ID: {
            return Object.assign({}, state, { notEditId: action.payload })
        }
        case BusinessQueAction.SET_NOT_EDIT_DATA: {
            return Object.assign({}, state, { notEditData: action.payload })
        }
        case BusinessQueAction.SET_BUSINESS_ADMIN_STATUS: {
            return Object.assign({}, state, { businessStatus: action.payload })
        }
        case BusinessQueAction.SET_SUBSCRIPTION_WISE_QUEUE: {
            return Object.assign({}, state, { subsQueueDetails: action.payload })
        }
        case BusinessQueAction.SET_SUBSCRIPTION_DETAILS: {
            return Object.assign({}, state, { subscriptionDetails: action.payload })
        }
        case BusinessQueAction.SET_EDITABLE_STATE: {
            return Object.assign({}, state, { isEditableQueue: action.payload })
        }
        case BusinessQueAction.SET_PAYMENT_MODAL_STATE: {
            return Object.assign({}, state, { paymentModal: action.payload })
        }
        case BusinessQueAction.SET_PAYMENT_DETAILS_STATE: {
            return Object.assign({}, state, { paymentDetails: action.payload })
        }
        case BusinessQueAction.SET_PAYMENT_TOKEN_STATE: {
            return Object.assign({}, state, { paymentToken: action.payload })
        }
        case BusinessQueAction.SET_ISPAYMENT_EXPIRE: {
            return Object.assign({}, state, { isPaymentExp: action.payload })
        }
        case BusinessQueAction.SET_EXPIRES_STATE: {
            return Object.assign({}, state, {
                expireStatus: action.payload.status,
                expireDetails: action.payload.details
            })
        }
        case signUpActions.SET_DEFAULT_STATE: {
            return initialState
        }
        default:
            return state
    }
}

export default queueParam
