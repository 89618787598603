/**
 * countries constant are create for all the country code of all countries mobile numbers
 */
export const countries = [
    { name: 'Afghanistan', flag: '🇦🇫', name_code: 'AF', code: '+93' },
    { name: 'Åland Islands', flag: '🇦🇽', name_code: 'AX', code: '+358' },
    { name: 'Albania', flag: '🇦🇱', name_code: 'AL', code: '+355' },
    { name: 'Algeria', flag: '🇩🇿', name_code: 'DZ', code: '+213' },
    { name: 'American Samoa', flag: '🇦🇸', name_code: 'AS', code: '+1684' },
    { name: 'Andorra', flag: '🇦🇩', name_code: 'AD', code: '+376' },
    { name: 'Angola', flag: '🇦🇴', name_code: 'AO', code: '+244' },
    { name: 'Anguilla', flag: '🇦🇮', name_code: 'AI', code: '+1264' },
    { name: 'Antarctica', flag: '🇦🇶', name_code: 'AQ', code: '+672' },
    { name: 'Antigua and Barbuda', flag: '🇦🇬', name_code: 'AG', code: '+1268' },
    { name: 'Argentina', flag: '🇦🇷', name_code: 'AR', code: '+54' },
    { name: 'Armenia', flag: '🇦🇲', name_code: 'AM', code: '+374' },
    { name: 'Aruba', flag: '🇦🇼', name_code: 'AW', code: '+297' },
    { name: 'Australia', flag: '🇦🇺', name_code: 'AU', code: '+61' },
    { name: 'Austria', flag: '🇦🇹', name_code: 'AT', code: '+43' },
    { name: 'Azerbaijan', flag: '🇦🇿', name_code: 'AZ', code: '+994' },
    { name: 'Bahamas', flag: '🇧🇸', name_code: 'BS', code: '+1242' },
    { name: 'Bahrain', flag: '🇧🇭', name_code: 'BH', code: '+973' },
    { name: 'Bangladesh', flag: '🇧🇩', name_code: 'BD', code: '+880' },
    { name: 'Barbados', flag: '🇧🇧', name_code: 'BB', code: '+1246' },
    { name: 'Belarus', flag: '🇧🇾', name_code: 'BY', code: '+375' },
    { name: 'Belgium', flag: '🇧🇪', name_code: 'BE', code: '+32' },
    { name: 'Belize', flag: '🇧🇿', name_code: 'BZ', code: '+501' },
    { name: 'Benin', flag: '🇧🇯', name_code: 'BJ', code: '+229' },
    { name: 'Bermuda', flag: '🇧🇲', name_code: 'BM', code: '+1441' },
    { name: 'Bhutan', flag: '🇧🇹', name_code: 'BT', code: '+975' },
    { name: 'Bolivia, Plurinational State of bolivia', flag: '🇧🇴', name_code: 'BO', code: '+591' },
    { name: 'Bosnia and Herzegovina', flag: '🇧🇦', name_code: 'BA', code: '+387' },
    { name: 'Botswana', flag: '🇧🇼', name_code: 'BW', code: '+267' },
    { name: 'Bouvet Island', flag: '🇧🇻', name_code: 'BV', code: '+47' },
    { name: 'Brazil', flag: '🇧🇷', name_code: 'BR', code: '+55' },
    { name: 'British Indian Ocean Territory', flag: '🇮🇴', name_code: 'IO', code: '+246' },
    { name: 'Brunei Darussalam', flag: '🇧🇳', name_code: 'BN', code: '+673' },
    { name: 'Bulgaria', flag: '🇧🇬', name_code: 'BG', code: '+359' },
    { name: 'Burkina Faso', flag: '🇧🇫', name_code: 'BF', code: '+226' },
    { name: 'Burundi', flag: '🇧🇮', name_code: 'BI', code: '+257' },
    { name: 'Cambodia', flag: '🇰🇭', name_code: 'KH', code: '+855' },
    { name: 'Cameroon', flag: '🇨🇲', name_code: 'CM', code: '+237' },
    { name: 'Canada', flag: '🇨🇦', name_code: 'CA', code: '+1' },
    { name: 'Cape Verde', flag: '🇨🇻', name_code: 'CV', code: '+238' },
    { name: 'Cayman Islands', flag: '🇰🇾', name_code: 'KY', code: '+345' },
    { name: 'Central African Republic', flag: '🇨🇫', name_code: 'CF', code: '+236' },
    { name: 'Chad', flag: '🇹🇩', name_code: 'TD', code: '+235' },
    { name: 'Chile', flag: '🇨🇱', name_code: 'CL', code: '+56' },
    { name: 'China', flag: '🇨🇳', name_code: 'CN', code: '+86' },
    { name: 'Christmas Island', flag: '🇨🇽', name_code: 'CX', code: '+61' },
    { name: 'Cocos (Keeling) Islands', flag: '🇨🇨', name_code: 'CC', code: '+61' },
    { name: 'Colombia', flag: '🇨🇴', name_code: 'CO', code: '+57' },
    { name: 'Comoros', flag: '🇰🇲', name_code: 'KM', code: '+269' },
    { name: 'Congo', flag: '🇨🇬', name_code: 'CG', code: '+242' },
    { name: 'Congo, The Democratic Republic of the Congo', flag: '🇨🇩', name_code: 'CD', code: '+243' },
    { name: 'Cook Islands', flag: '🇨🇰', name_code: 'CK', code: '+682' },
    { name: 'Costa Rica', flag: '🇨🇷', name_code: 'CR', code: '+506' },
    { name: "Cote d'Ivoire", flag: '🇨🇮', name_code: 'CI', code: '+225' },
    { name: 'Croatia', flag: '🇭🇷', name_code: 'HR', code: '+385' },
    { name: 'Cuba', flag: '🇨🇺', name_code: 'CU', code: '+53' },
    { name: 'Cyprus', flag: '🇨🇾', name_code: 'CY', code: '+357' },
    { name: 'Czech Republic', flag: '🇨🇿', name_code: 'CZ', code: '+420' },
    { name: 'Denmark', flag: '🇩🇰', name_code: 'DK', code: '+45' },
    { name: 'Djibouti', flag: '🇩🇯', name_code: 'DJ', code: '+253' },
    { name: 'Dominica', flag: '🇩🇲', name_code: 'DM', code: '+1767' },
    { name: 'Dominican Republic', flag: '🇩🇴', name_code: 'DO', code: '+1849' },
    { name: 'Ecuador', flag: '🇪🇨', name_code: 'EC', code: '+593' },
    { name: 'Egypt', flag: '🇪🇬', name_code: 'EG', code: '+20' },
    { name: 'El Salvador', flag: '🇸🇻', name_code: 'SV', code: '+503' },
    { name: 'Equatorial Guinea', flag: '🇬🇶', name_code: 'GQ', code: '+240' },
    { name: 'Eritrea', flag: '🇪🇷', name_code: 'ER', code: '+291' },
    { name: 'Estonia', flag: '🇪🇪', name_code: 'EE', code: '+372' },
    { name: 'Ethiopia', flag: '🇪🇹', name_code: 'ET', code: '+251' },
    { name: 'Falkland Islands (Malvinas)', flag: '🇫🇰', name_code: 'FK', code: '+500' },
    { name: 'Faroe Islands', flag: '🇫🇴', name_code: 'FO', code: '+298' },
    { name: 'Fiji', flag: '🇫🇯', name_code: 'FJ', code: '+679' },
    { name: 'Finland', flag: '🇫🇮', name_code: 'FI', code: '+358' },
    { name: 'France', flag: '🇫🇷', name_code: 'FR', code: '+33' },
    { name: 'French Guiana', flag: '🇬🇫', name_code: 'GF', code: '+594' },
    { name: 'French Polynesia', flag: '🇵🇫', name_code: 'PF', code: '+689' },
    { name: 'French Southern Territories', flag: '🇹🇫', name_code: 'TF', code: '+262' },
    { name: 'Gabon', flag: '🇬🇦', name_code: 'GA', code: '+241' },
    { name: 'Gambia', flag: '🇬🇲', name_code: 'GM', code: '+220' },
    { name: 'Georgia', flag: '🇬🇪', name_code: 'GE', code: '+995' },
    { name: 'Germany', flag: '🇩🇪', name_code: 'DE', code: '+49' },
    { name: 'Ghana', flag: '🇬🇭', name_code: 'GH', code: '+233' },
    { name: 'Gibraltar', flag: '🇬🇮', name_code: 'GI', code: '+350' },
    { name: 'Greece', flag: '🇬🇷', name_code: 'GR', code: '+30' },
    { name: 'Greenland', flag: '🇬🇱', name_code: 'GL', code: '+299' },
    { name: 'Grenada', flag: '🇬🇩', name_code: 'GD', code: '+1473' },
    { name: 'Guadeloupe', flag: '🇬🇵', name_code: 'GP', code: '+590' },
    { name: 'Guam', flag: '🇬🇺', name_code: 'GU', code: '+1671' },
    { name: 'Guatemala', flag: '🇬🇹', name_code: 'GT', code: '+502' },
    { name: 'Guernsey', flag: '🇬🇬', name_code: 'GG', code: '+44' },
    { name: 'Guinea', flag: '🇬🇳', name_code: 'GN', code: '+224' },
    { name: 'Guinea-Bissau', flag: '🇬🇼', name_code: 'GW', code: '+245' },
    { name: 'Guyana', flag: '🇬🇾', name_code: 'GY', code: '+592' },
    { name: 'Haiti', flag: '🇭🇹', name_code: 'HT', code: '+509' },
    { name: 'Heard Island and Mcdonald Islands', flag: '🇭🇲', name_code: 'HM', code: '+672' },
    { name: 'Holy See (Vatican City State)', flag: '🇻🇦', name_code: 'VA', code: '+379' },
    { name: 'Honduras', flag: '🇭🇳', name_code: 'HN', code: '+504' },
    { name: 'Hong Kong', flag: '🇭🇰', name_code: 'HK', code: '+852' },
    { name: 'Hungary', flag: '🇭🇺', name_code: 'HU', code: '+36' },
    { name: 'Iceland', flag: '🇮🇸', name_code: 'IS', code: '+354' },
    { name: 'India', flag: '🇮🇳', name_code: 'IN', code: '+91' },
    { name: 'Indonesia', flag: '🇮🇩', name_code: 'ID', code: '+62' },
    { name: 'Iran, Islamic Republic of Persian Gulf', flag: '🇮🇷', name_code: 'IR', code: '+98' },
    { name: 'Iraq', flag: '🇮🇶', name_code: 'IQ', code: '+964' },
    { name: 'Ireland', flag: '🇮🇪', name_code: 'IE', code: '+353' },
    { name: 'Isle of Man', flag: '🇮🇲', name_code: 'IM', code: '+44' },
    { name: 'Israel', flag: '🇮🇱', name_code: 'IL', code: '+972' },
    { name: 'Italy', flag: '🇮🇹', name_code: 'IT', code: '+39' },
    { name: 'Jamaica', flag: '🇯🇲', name_code: 'JM', code: '+1876' },
    { name: 'Japan', flag: '🇯🇵', name_code: 'JP', code: '+81' },
    { name: 'Jersey', flag: '🇯🇪', name_code: 'JE', code: '+44' },
    { name: 'Jordan', flag: '🇯🇴', name_code: 'JO', code: '+962' },
    { name: 'Kazakhstan', flag: '🇰🇿', name_code: 'KZ', code: '+7' },
    { name: 'Kenya', flag: '🇰🇪', name_code: 'KE', code: '+254' },
    { name: 'Kiribati', flag: '🇰🇮', name_code: 'KI', code: '+686' },
    { name: "Korea, Democratic People's Republic of Korea", flag: '🇰🇵', name_code: 'KP', code: '+850' },
    { name: 'Korea, Republic of South Korea', flag: '🇰🇷', name_code: 'KR', code: '+82' },
    { name: 'Kosovo', flag: '🇽🇰', name_code: 'XK', code: '+383' },
    { name: 'Kuwait', flag: '🇰🇼', name_code: 'KW', code: '+965' },
    { name: 'Kyrgyzstan', flag: '🇰🇬', name_code: 'KG', code: '+996' },
    { name: 'Laos', flag: '🇱🇦', name_code: 'LA', code: '+856' },
    { name: 'Latvia', flag: '🇱🇻', name_code: 'LV', code: '+371' },
    { name: 'Lebanon', flag: '🇱🇧', name_code: 'LB', code: '+961' },
    { name: 'Lesotho', flag: '🇱🇸', name_code: 'LS', code: '+266' },
    { name: 'Liberia', flag: '🇱🇷', name_code: 'LR', code: '+231' },
    { name: 'Libyan Arab Jamahiriya', flag: '🇱🇾', name_code: 'LY', code: '+218' },
    { name: 'Liechtenstein', flag: '🇱🇮', name_code: 'LI', code: '+423' },
    { name: 'Lithuania', flag: '🇱🇹', name_code: 'LT', code: '+370' },
    { name: 'Luxembourg', flag: '🇱🇺', name_code: 'LU', code: '+352' },
    { name: 'Macao', flag: '🇲🇴', name_code: 'MO', code: '+853' },
    { name: 'Macedonia', flag: '🇲🇰', name_code: 'MK', code: '+389' },
    { name: 'Madagascar', flag: '🇲🇬', name_code: 'MG', code: '+261' },
    { name: 'Malawi', flag: '🇲🇼', name_code: 'MW', code: '+265' },
    { name: 'Malaysia', flag: '🇲🇾', name_code: 'MY', code: '+60' },
    { name: 'Maldives', flag: '🇲🇻', name_code: 'MV', code: '+960' },
    { name: 'Mali', flag: '🇲🇱', name_code: 'ML', code: '+223' },
    { name: 'Malta', flag: '🇲🇹', name_code: 'MT', code: '+356' },
    { name: 'Marshall Islands', flag: '🇲🇭', name_code: 'MH', code: '+692' },
    { name: 'Martinique', flag: '🇲🇶', name_code: 'MQ', code: '+596' },
    { name: 'Mauritania', flag: '🇲🇷', name_code: 'MR', code: '+222' },
    { name: 'Mauritius', flag: '🇲🇺', name_code: 'MU', code: '+230' },
    { name: 'Mayotte', flag: '🇾🇹', name_code: 'YT', code: '+262' },
    { name: 'Mexico', flag: '🇲🇽', name_code: 'MX', code: '+52' },
    { name: 'Micronesia, Federated States of Micronesia', flag: '🇫🇲', name_code: 'FM', code: '+691' },
    { name: 'Moldova', flag: '🇲🇩', name_code: 'MD', code: '+373' },
    { name: 'Monaco', flag: '🇲🇨', name_code: 'MC', code: '+377' },
    { name: 'Mongolia', flag: '🇲🇳', name_code: 'MN', code: '+976' },
    { name: 'Montenegro', flag: '🇲🇪', name_code: 'ME', code: '+382' },
    { name: 'Montserrat', flag: '🇲🇸', name_code: 'MS', code: '+1664' },
    { name: 'Morocco', flag: '🇲🇦', name_code: 'MA', code: '+212' },
    { name: 'Mozambique', flag: '🇲🇿', name_code: 'MZ', code: '+258' },
    { name: 'Myanmar', flag: '🇲🇲', name_code: 'MM', code: '+95' },
    { name: 'Namibia', flag: '🇳🇦', name_code: 'NA', code: '+264' },
    { name: 'Nauru', flag: '🇳🇷', name_code: 'NR', code: '+674' },
    { name: 'Nepal', flag: '🇳🇵', name_code: 'NP', code: '+977' },
    { name: 'Netherlands', flag: '🇳🇱', name_code: 'NL', code: '+31' },
    { name: 'Netherlands Antilles', flag: '', name_code: 'AN', code: '+599' },
    { name: 'New Caledonia', flag: '🇳🇨', name_code: 'NC', code: '+687' },
    { name: 'New Zealand', flag: '🇳🇿', name_code: 'NZ', code: '+64' },
    { name: 'Nicaragua', flag: '🇳🇮', name_code: 'NI', code: '+505' },
    { name: 'Niger', flag: '🇳🇪', name_code: 'NE', code: '+227' },
    { name: 'Nigeria', flag: '🇳🇬', name_code: 'NG', code: '+234' },
    { name: 'Niue', flag: '🇳🇺', name_code: 'NU', code: '+683' },
    { name: 'Norfolk Island', flag: '🇳🇫', name_code: 'NF', code: '+672' },
    { name: 'Northern Mariana Islands', flag: '🇲🇵', name_code: 'MP', code: '+1670' },
    { name: 'Norway', flag: '🇳🇴', name_code: 'NO', code: '+47' },
    { name: 'Oman', flag: '🇴🇲', name_code: 'OM', code: '+968' },
    { name: 'Pakistan', flag: '🇵🇰', name_code: 'PK', code: '+92' },
    { name: 'Palau', flag: '🇵🇼', name_code: 'PW', code: '+680' },
    { name: 'Palestinian Territory, Occupied', flag: '🇵🇸', name_code: 'PS', code: '+970' },
    { name: 'Panama', flag: '🇵🇦', name_code: 'PA', code: '+507' },
    { name: 'Papua New Guinea', flag: '🇵🇬', name_code: 'PG', code: '+675' },
    { name: 'Paraguay', flag: '🇵🇾', name_code: 'PY', code: '+595' },
    { name: 'Peru', flag: '🇵🇪', name_code: 'PE', code: '+51' },
    { name: 'Philippines', flag: '🇵🇭', name_code: 'PH', code: '+63' },
    { name: 'Pitcairn', flag: '🇵🇳', name_code: 'PN', code: '+64' },
    { name: 'Poland', flag: '🇵🇱', name_code: 'PL', code: '+48' },
    { name: 'Portugal', flag: '🇵🇹', name_code: 'PT', code: '+351' },
    { name: 'Puerto Rico', flag: '🇵🇷', name_code: 'PR', code: '+1939' },
    { name: 'Qatar', flag: '🇶🇦', name_code: 'QA', code: '+974' },
    { name: 'Romania', flag: '🇷🇴', name_code: 'RO', code: '+40' },
    { name: 'Russia', flag: '🇷🇺', name_code: 'RU', code: '+7' },
    { name: 'Rwanda', flag: '🇷🇼', name_code: 'RW', code: '+250' },
    { name: 'Reunion', flag: '🇷🇪', name_code: 'RE', code: '+262' },
    { name: 'Saint Barthelemy', flag: '🇧🇱', name_code: 'BL', code: '+590' },
    { name: 'Saint Helena, Ascension and Tristan Da Cunha', flag: '🇸🇭', name_code: 'SH', code: '+290' },
    { name: 'Saint Kitts and Nevis', flag: '🇰🇳', name_code: 'KN', code: '+1869' },
    { name: 'Saint Lucia', flag: '🇱🇨', name_code: 'LC', code: '+1758' },
    { name: 'Saint Martin', flag: '🇲🇫', name_code: 'MF', code: '+590' },
    { name: 'Saint Pierre and Miquelon', flag: '🇵🇲', name_code: 'PM', code: '+508' },
    { name: 'Saint Vincent and the Grenadines', flag: '🇻🇨', name_code: 'VC', code: '+1784' },
    { name: 'Samoa', flag: '🇼🇸', name_code: 'WS', code: '+685' },
    { name: 'San Marino', flag: '🇸🇲', name_code: 'SM', code: '+378' },
    { name: 'Sao Tome and Principe', flag: '🇸🇹', name_code: 'ST', code: '+239' },
    { name: 'Saudi Arabia', flag: '🇸🇦', name_code: 'SA', code: '+966' },
    { name: 'Senegal', flag: '🇸🇳', name_code: 'SN', code: '+221' },
    { name: 'Serbia', flag: '🇷🇸', name_code: 'RS', code: '+381' },
    { name: 'Seychelles', flag: '🇸🇨', name_code: 'SC', code: '+248' },
    { name: 'Sierra Leone', flag: '🇸🇱', name_code: 'SL', code: '+232' },
    { name: 'Singapore', flag: '🇸🇬', name_code: 'SG', code: '+65' },
    { name: 'Slovakia', flag: '🇸🇰', name_code: 'SK', code: '+421' },
    { name: 'Slovenia', flag: '🇸🇮', name_code: 'SI', code: '+386' },
    { name: 'Solomon Islands', flag: '🇸🇧', name_code: 'SB', code: '+677' },
    { name: 'Somalia', flag: '🇸🇴', name_code: 'SO', code: '+252' },
    { name: 'South Africa', flag: '🇿🇦', name_code: 'ZA', code: '+27' },
    { name: 'South Sudan', flag: '🇸🇸', name_code: 'SS', code: '+211' },
    { name: 'South Georgia and the South Sandwich Islands', flag: '🇬🇸', name_code: 'GS', code: '+500' },
    { name: 'Spain', flag: '🇪🇸', name_code: 'ES', code: '+34' },
    { name: 'Sri Lanka', flag: '🇱🇰', name_code: 'LK', code: '+94' },
    { name: 'Sudan', flag: '🇸🇩', name_code: 'SD', code: '+249' },
    { name: 'Suriname', flag: '🇸🇷', name_code: 'SR', code: '+597' },
    { name: 'Svalbard and Jan Mayen', flag: '🇸🇯', name_code: 'SJ', code: '+47' },
    { name: 'Eswatini', flag: '🇸🇿', name_code: 'SZ', code: '+268' },
    { name: 'Sweden', flag: '🇸🇪', name_code: 'SE', code: '+46' },
    { name: 'Switzerland', flag: '🇨🇭', name_code: 'CH', code: '+41' },
    { name: 'Syrian Arab Republic', flag: '🇸🇾', name_code: 'SY', code: '+963' },
    { name: 'Taiwan', flag: '🇹🇼', name_code: 'TW', code: '+886' },
    { name: 'Tajikistan', flag: '🇹🇯', name_code: 'TJ', code: '+992' },
    { name: 'Tanzania, United Republic of Tanzania', flag: '🇹🇿', name_code: 'TZ', code: '+255' },
    { name: 'Thailand', flag: '🇹🇭', name_code: 'TH', code: '+66' },
    { name: 'Timor-Leste', flag: '🇹🇱', name_code: 'TL', code: '+670' },
    { name: 'Togo', flag: '🇹🇬', name_code: 'TG', code: '+228' },
    { name: 'Tokelau', flag: '🇹🇰', name_code: 'TK', code: '+690' },
    { name: 'Tonga', flag: '🇹🇴', name_code: 'TO', code: '+676' },
    { name: 'Trinidad and Tobago', flag: '🇹🇹', name_code: 'TT', code: '+1868' },
    { name: 'Tunisia', flag: '🇹🇳', name_code: 'TN', code: '+216' },
    { name: 'Turkey', flag: '🇹🇷', name_code: 'TR', code: '+90' },
    { name: 'Turkmenistan', flag: '🇹🇲', name_code: 'TM', code: '+993' },
    { name: 'Turks and Caicos Islands', flag: '🇹🇨', name_code: 'TC', code: '+1649' },
    { name: 'Tuvalu', flag: '🇹🇻', name_code: 'TV', code: '+688' },
    { name: 'Uganda', flag: '🇺🇬', name_code: 'UG', code: '+256' },
    { name: 'Ukraine', flag: '🇺🇦', name_code: 'UA', code: '+380' },
    { name: 'United Arab Emirates', flag: '🇦🇪', name_code: 'AE', code: '+971' },
    { name: 'United Kingdom', flag: '🇬🇧', name_code: 'GB', code: '+44' },
    { name: 'United States', flag: '🇺🇸', name_code: 'US', code: '+1' },
    { name: 'Uruguay', flag: '🇺🇾', name_code: 'UY', code: '+598' },
    { name: 'Uzbekistan', flag: '🇺🇿', name_code: 'UZ', code: '+998' },
    { name: 'Vanuatu', flag: '🇻🇺', name_code: 'VU', code: '+678' },
    { name: 'Venezuela, Bolivarian Republic of Venezuela', flag: '🇻🇪', name_code: 'VE', code: '+58' },
    { name: 'Vietnam', flag: '🇻🇳', name_code: 'VN', code: '+84' },
    { name: 'Virgin Islands, British', flag: '🇻🇬', name_code: 'VG', code: '+1284' },
    { name: 'Virgin Islands, U.S.', flag: '🇻🇮', name_code: 'VI', code: '+1340' },
    { name: 'Wallis and Futuna', flag: '🇼🇫', name_code: 'WF', code: '+681' },
    { name: 'Yemen', flag: '🇾🇪', name_code: 'YE', code: '+967' },
    { name: 'Zambia', flag: '🇿🇲', name_code: 'ZM', code: '+260' },
    { name: 'Zimbabwe', flag: '🇿🇼', name_code: 'ZW', code: '+263' }
]
