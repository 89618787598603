/* eslint-disable react/prop-types */
import React, { useState, useEffect, lazy, Suspense } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Auth } from 'aws-amplify'
import { fetchToken, onMessageListener } from './firebase'
import firebase from 'firebase/compat/app'
import { editBranchSuperAdmin } from './components/common-comps/super-admin-profile/api-helper/api-helper'
import { getFcmToken } from './components/queue-admin/manage-queue-component/api-helper/api-helper'
import { openNotification } from './utils/components/notification'

import { initializeApp } from 'firebase/app'
import { firebaseConfig } from './firebaseconfig'

import './App.less'
import { isLoggedIn } from './awsGetToken'
import useAPICall from './hooks/useAPICall'
import { getQueueData } from './components/queue-admin/manage-queue-component/api-helper/api-helper'
import { getQueueBranchAdminData } from './components/business-admin/master-dir-component/api-helper/api-helper'
import LogInComp from './components/common-comps/auth-component'
import ForgotPassword from './components/common-comps/auth-component/inner-comp/forgot-pass'
import ProtectedRoutes from './utils/components/ProtectedRoutes'
import SpinnerComp from './components/common-comps/spinner'
import SuperAdmin from './components/common-comps/super-admin-profile/admin-profile'
import Subscription from './components/common-comps/super-admin-profile/subscription'
import { getGeoCountryInfo } from './utils/constant'
// ReqireAuth component authentication guard if user !logged in RequireAuth return to the sign in
const RequireAuth = lazy(() => import('./utils/components/RequireAuth'))
const LoginAuth = lazy(() => import('./utils/components/LoginAuth'))

/* Auth.configure from aws-amplify it set the configuration of AWS Credentials */
Auth.configure({
    region: process.env.REACT_APP_AWS_POOL_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_CLIENT_ID
})
/**
 * App is functional component it make allover app routing
 * initializeApp(firebaseConfig) -- > inialize with firebase credentials
 *  { get } = useAPICall() --- > get is hook value getting value from useAPICall custom hook
 *  userType --- > it is type to get in localStorage for whichtype of user are log in
 * @returns Jsx element with what are the existing route needs to be render
 */
function App() {
    initializeApp(firebaseConfig)
    const { get } = useAPICall()
    const dispatch = useDispatch()
    const userType = localStorage.getItem('userType')
    const [loading, setLoading] = useState(false)
    const [loggedIn, setLoggedIn] = useState(null)

    /* this useEffect written for the checking user log in or not with getSessionState() */
    useEffect(() => {
        async function getSessionState() {
            const _loggedIn = await isLoggedIn()
            setLoggedIn(_loggedIn)
            setLoading(false)
        }
        getSessionState()
        getGeoCountryInfo(dispatch)
    }, [])

    /* this useEffect written for to check logged user are which type then api call*/
    useEffect(() => {
        if (loggedIn && loggedIn === true && window.location.pathname == '/auth/sign-in') {
            if (userType === 'queue_admin') {
                window.history.pushState({}, '', '/queue-admin/manage-queue/error')
            } else if (userType === 'branch_admin') {
                window.history.pushState({}, '', '/partner/master-directory')
            } else {
                window.history.pushState({}, '', '/concorde/master-directory/businesses')
            }
        } else if (loggedIn && userType !== null && window.location.pathname == '/auth/sign-in') {
            sessionStorage.clear()
            localStorage.clear()
        } else if (userType === 'queue_admin') {
            getQueueData(get, dispatch)
        } else if (userType === 'branch_admin') {
            getQueueBranchAdminData(get, dispatch, 1, 1000)
        }
    }, [loggedIn])

    // loading state for fallback ui
    if (loading) {
        return <SpinnerComp />
    }

    /**
     *
     * firebase START--->>>
     */
    const { patch, put } = useAPICall()
    const [tokenData, setTokenData] = useState({ tokenFound: false, token: null })
    const [gotNotification, setGotNotification] = useState(false)
    const [messageNotification, setMessageNotification] = useState({ title: null, body: null })

    useEffect(() => {
        if (!firebase.apps.length && loggedIn) {
            fetchToken(setTokenData)
        }
    }, [loggedIn])

    const sendTokenDetails = async (token) => {
        let data
        if (userType === 'branch_admin') {
            data = await editBranchSuperAdmin(patch, { fcmToken: token })
        } else if (userType === 'queue_admin') {
            data = await getFcmToken(put, { fcmToken: token })
        }
        console.log(data, 'getting token in backend')
    }

    onMessageListener()
        .then((payload) => {
            setGotNotification(true)
            setMessageNotification({ title: payload.notification.title, body: payload.notification.body })
        })
        .catch((err) => console.log('failed: ', err))

    useEffect(() => {
        if (!tokenData.tokenFound) return
        else if (tokenData.tokenFound) {
            sendTokenDetails(tokenData.token)
        }
    }, [tokenData])

    useEffect(() => {
        if (gotNotification) {
            if (!messageNotification.title && !messageNotification.body) return
            else if (messageNotification.title && messageNotification.body) {
                openNotification(messageNotification, setGotNotification)
            }
        }
    }, [messageNotification])

    /**
     *
     * firebase End--->>>
     */

    const onFocus = async () => {
        const _loggedIn = await isLoggedIn()
        setLoggedIn(_loggedIn)
    }

    useEffect(() => {
        window.addEventListener('focus', onFocus)
        onFocus()
        return () => {
            window.removeEventListener('focus', onFocus)
        }
    }, [])

    return (
        <>
            <Router>
                <Routes>
                    <Route
                        path="/auth/sign-in"
                        element={
                            <Suspense fallback={<SpinnerComp />}>
                                <LoginAuth loggedIn={loggedIn}>
                                    <Suspense fallback={<SpinnerComp />}>
                                        <LogInComp setLoggedIn={setLoggedIn} />
                                    </Suspense>
                                </LoginAuth>
                            </Suspense>
                        }
                    />
                    <Route
                        path="/auth/forgot-password"
                        element={
                            <Suspense fallback={<SpinnerComp />}>
                                <ForgotPassword />
                            </Suspense>
                        }
                    />
                    <Route
                        path="/branch-admin-profile"
                        element={
                            <Suspense fallback={<SpinnerComp />}>
                                <SuperAdmin />
                            </Suspense>
                        }
                    />
                    <Route
                        path="/subscription"
                        element={
                            <Suspense fallback={<SpinnerComp />}>
                                <Subscription />
                            </Suspense>
                        }
                    />
                    <Route
                        path="/*"
                        element={
                            <Suspense fallback={<SpinnerComp />}>
                                <RequireAuth loggedIn={loggedIn}>
                                    <Suspense fallback={<SpinnerComp />}>
                                        <ProtectedRoutes />
                                    </Suspense>
                                </RequireAuth>
                            </Suspense>
                        }
                    />
                </Routes>
            </Router>
        </>
    )
}

export default App
