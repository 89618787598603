import { signUpActions } from './types'
/**
 * setIsSignedIn, setLoginEmail, setLogOutModalVisible, setUserLoginDefault, setQRModalVisible, setNotIssueCount
 * is all the funtion for using to set the value of reducer state
 * @param {*} payload is the what are the value sended to that function
 * @returns object with type of what type change and payload
 */

export function setIsSignedIn(payload) {
    return { type: signUpActions.SET_IS_SIGNED_IN, payload }
}

export function setLoginEmail(payload) {
    return { type: signUpActions.SET_LOGIN_EMAIL, payload }
}

export function setLogOutModalVisible(payload) {
    return { type: signUpActions.SET_LOG_OUT_MODAL, payload }
}
export function setUserLoginDefault() {
    return { type: signUpActions.SET_DEFAULT_STATE }
}

export function setDefaultContryCode(payload) {
    return { type: signUpActions.SET_DEFAULT_CONTRY_CODE, payload }
}
// for QR CODE

export function setQRModalVisible(payload) {
    return { type: signUpActions.SET_QR_CODE_MODAL_STATE, payload }
}
// FOR THE HOW MANY NOTIFICATION COUNT =====>
export function setNotIssueCount(payload) {
    return { type: signUpActions.SET_NOTIFICATION_COUNT, payload }
}

export function setQueueTimeWiseActive(payload) {
    return { type: signUpActions.SET_QUEUE_TIMING_WISE_ACTIVE, payload }
}
// ======>
