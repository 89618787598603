// QueueAdminAction, signUpActions  are the types for written in types.js

import { QueueAdminAction, signUpActions } from '../actions/types'

/**
 * initialState is object to define all are the initial states
 */
const initialState = {
    qAdminSideBarQueues: [],
    loading: false,
    emptyManageQueues: false,
    caseId: ''
}

/**
 * QueueAdminR function are the reducer function to return the what state is changes to check that and update that
 * @param {*} state state is the values of default initialState
 * @param {*} action action is the object have type and payload
 * @returns it returns eigther state what should update or default state
 */
function QueueAdminR(state = initialState, action) {
    //checking action.type is sended type and set action.payload
    switch (action.type) {
        case QueueAdminAction.SET_Q_ADMIN_QUEUE_DETAILS: {
            let temp
            temp = action.payload
            if (temp && temp.queue.length > 0) {
                temp = temp.queue.filter((item) => item.queue_status === true)
            } else {
                temp = []
            }
            return Object.assign({}, state, { qAdminSideBarQueues: temp })
        }
        case QueueAdminAction.SET_Q_ADMIN_QUEUE_LOADING: {
            return Object.assign({}, state, { loading: action.payload })
        }
        case QueueAdminAction.SET_MANAGE_QUEUE_EMPTY: {
            return Object.assign({}, state, { emptyManageQueues: action.payload })
        }

        case signUpActions.SET_DEFAULT_STATE: {
            return initialState
        }

        default:
            return state
    }
}
export default QueueAdminR
