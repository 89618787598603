import React from 'react'
import { Col, Button } from 'antd'
import AuthStyle from '../../auth.module.scss'
import ImgBanner from '../../../../../assets/auth/auth-qr-scan.png'
import ImgTopBanner from '../../../../../assets/auth/top-left.png'
import ImgBottomBanner from '../../../../../assets/auth/bottom-right.png'
import ImgBannerLogo from '../../../../../assets/auth/auth-logo.png'
// import ImgBannerLogo from '../../../../../assets/auth/auth-logo-2.png'
import { DownCircleOutlined } from '@ant-design/icons'
import useWindowdimension from '../../../../../hooks/useWindowDimensions'

export default function index() {
    const { width } = useWindowdimension()
    const scrollingFunc = () => {
        window.scrollTo(0, 800)
    }
    return (
        <>
            <Col
                xl={{ span: 12, order: 2 }}
                lg={{ span: 12, order: 2 }}
                md={{ span: 24, order: 1 }}
                sm={{ span: 24, order: 1 }}
                xs={{ span: 24, order: 1 }}
                className={AuthStyle.bannerMain}>
                <div className={AuthStyle.banner_top}>
                    <img className={AuthStyle.banner_top_img} src={ImgTopBanner} alt="banner-top-img" />
                </div>
                <div className={AuthStyle.banner}>
                    {/* <img className={AuthStyle.banner_logo} src={ImgBannerLogo} alt="banner-img-logo" />
                    <h1 className={AuthStyle.heading}>QPlease</h1>
                    <h5 className={AuthStyle.tagline}>Get all your ducks in a row</h5> */}
                    <div className={AuthStyle.newStyle}>
                        <div>
                            <img className={AuthStyle.banner_logo} src={ImgBannerLogo} alt="banner-img-logo" />
                            <h5 className={AuthStyle.tagline}>Get all your ducks in a row</h5>
                        </div>
                        <div>
                            <h1 className={AuthStyle.heading}>QPlease</h1>
                        </div>
                    </div>
                    <div className={AuthStyle.banner_img}>
                        <img src={ImgBanner} alt="banner-img" />
                    </div>
                    <h2 className={AuthStyle.sec_heading}>Scan and be anywhere</h2>
                    {width < 992 ? (
                        <Button onClick={() => scrollingFunc()} type="text" className={AuthStyle.scrollTo}>
                            <DownCircleOutlined className={AuthStyle.btn} />
                        </Button>
                    ) : null}
                </div>
                <div className={AuthStyle.banner_bottom}>
                    <img className={AuthStyle.banner_bottom_img} src={ImgBottomBanner} alt="banner-bottom-img" />
                </div>
            </Col>
        </>
    )
}
