import { auth } from '../../../../utils/eps'

export async function userGet(loginGet, emailId) {
    try {
        const response = await loginGet(auth.getUser(emailId))
        return response
    } catch (error) {
        console.log(error, 'error in get user')
        throw error.message
    }
}
