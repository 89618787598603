import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const antIcon = <LoadingOutlined style={{ fontSize: 35, fontWeight: 700 }} spin />
/**
 * Spinner component
 * @returns jsx element of spinner for loading state
 */
export default function index() {
    return (
        <>
            <div className="centerSpinnerForLazyComponents">
                <Spin indicator={antIcon} />
            </div>
        </>
    )
}
