/**
 *  antd notification for notifying user
 */
import { notification } from 'antd'

/**
 *
 * This function shows notification to user
 *
 * @param {*} messageNotification
 * messageNotification state
 * messageNotification which holds
 * 1. message
 * 2. descriptin of the message
 * 3. duration of 6 seconds
 * @param {*} setGotNotification
 * setGotNotification function is used to update messageNotification state
 */
export const openNotification = (messageNotification, setGotNotification) => {
    /**
     * setting arguments for notification
     */
    const args = {
        message: messageNotification.title,
        description: messageNotification.body,
        // notification will be vanish after 6 seconds
        duration: 6,
        // it will be placed on top right corner
        placement: 'topRight',
        // it will placed 100 pixels from top of the screen
        top: 100
    }
    // setting args to notification
    notification.info(args)
    // setting gotNotification value to false
    setGotNotification(false)
}
