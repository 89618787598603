import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AcceptHosted } from 'react-acceptjs'
import { Modal } from 'antd'
import { setPaymenntModal } from '../../../../../redux/actions/businessQue.action'
import adminModal from '../../superAdmin.module.scss'
/**
 * PayModal component
 * AcceptHosted ---> for choosed package payment
 * dispatch ---> for Modal true or false
 * useSelector ---> for get value from redux store
 * @returns jsx element payment Modal
 */
export default function PayModal() {
    const dispatch = useDispatch()
    const paymentModal = useSelector((state) => state.queueParam.paymentModal)
    const paymentDetails = useSelector((state) => state.queueParam.paymentDetails)
    const paymentToken = useSelector((state) => state.queueParam.paymentToken)

    const handleTransactionResponse = (response) => {
        console.log(response, 'ssss')
    }
    return (
        <>
            <Modal
                centered
                open={paymentModal}
                footer={null}
                className={adminModal.paymentModal}
                onOk={() => dispatch(setPaymenntModal(false))}
                onCancel={() => dispatch(setPaymenntModal(false))}>
                <div className={adminModal.headerPart}>
                    <h3>
                        Pay for the “{paymentDetails && paymentDetails.packageName ? paymentDetails.packageName : null}{' '}
                        Package” you selected.
                    </h3>
                    <p>Click on “Pay now” and you will be redirected to the payment page.</p>
                    <div className={adminModal.payBtnPart}>
                        <AcceptHosted
                            formToken={paymentToken}
                            integration="redirect"
                            // environment="PRODUCTION"
                            environment="SANDBOX"
                            onTransactionResponse={handleTransactionResponse}>
                            <div id={adminModal['payment-btn']} className="ant-btn ant-btn-primary">
                                Pay $
                                {paymentDetails && paymentDetails.packageAmount ? paymentDetails.packageAmount : null}{' '}
                                now
                            </div>
                        </AcceptHosted>
                    </div>
                </div>
            </Modal>
        </>
    )
}
