import { QueueAdminAction, signUpActions } from './types'

/**
 * setQAdminQueues, setLoading, setQueueListEmpty, setQAdminDefault
 * is all the funtion for using to set the value of reducer state
 * @param {*} payload is the what are the value sended to that function
 * @returns object with type of what type change and payload
 */

export function setQAdminQueues(payload) {
    return { type: QueueAdminAction.SET_Q_ADMIN_QUEUE_DETAILS, payload }
}
export function setLoading(payload) {
    return { type: QueueAdminAction.SET_Q_ADMIN_QUEUE_LOADING, payload }
}
export function setQueueListEmpty(payload) {
    return { type: QueueAdminAction.SET_MANAGE_QUEUE_EMPTY, payload }
}

export function setQAdminDefault() {
    return { type: signUpActions.SET_DEFAULT_STATE }
}
