import axios from 'axios'
import { message } from 'antd'
import { setDefaultContryCode } from '../redux/actions/signUp.action'

export const getGeoCountryInfo = (dispatch) => {
    axios
        .get('https://ipapi.co/json')
        .then((response) => {
            let data = response.data
            dispatch(setDefaultContryCode(data))
        })
        .catch((error) => {
            console.log(error)
            message.error(error.message)
        })
}
