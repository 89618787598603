// signUpActions  are the types for written in types.js
import { signUpActions } from './../actions/types'
/**
 * initialState is object to define all are the initial states
 */
const initialState = {
    isSignedIn: sessionStorage.getItem('isSignIn') ? true : false,
    userType: sessionStorage.getItem('userType'),
    loginEmail: '',
    logOutModalVisible: false,
    notIssuesCount: 0,
    qrModalVisible: false,
    defaultCountry: {},
    queueIsDisabled: false
}
/**
 * signUp function are the reducer function to return the what state is changes to check that and update that
 * @param {*} state state is the values of default initialState
 * @param {*} action action is the object have type and payload
 * @returns it returns eigther state what should update or default state
 */
function signUp(state = initialState, action) {
    //checking action.type is sended type and set action.payload
    switch (action.type) {
        case signUpActions.SET_IS_SIGNED_IN:
            sessionStorage.setItem('userType', action.payload)
            sessionStorage.setItem('isSignIn', true)
            return Object.assign({}, state, { isSignedIn: action.payload ? true : false, userType: action.payload })

        case signUpActions.SET_LOGIN_EMAIL: {
            return Object.assign({}, state, { loginEmail: action.payload })
        }
        case signUpActions.SET_LOG_OUT_MODAL: {
            return Object.assign({}, state, { logOutModalVisible: action.payload })
        }
        case signUpActions.SET_NOTIFICATION_COUNT: {
            return Object.assign({}, state, { notIssuesCount: action.payload })
        }
        case signUpActions.SET_DEFAULT_STATE: {
            return initialState
        }
        case signUpActions.SET_DEFAULT_CONTRY_CODE: {
            return Object.assign({}, state, { defaultCountry: action.payload })
        }
        case signUpActions.SET_QR_CODE_MODAL_STATE: {
            return Object.assign({}, state, { qrModalVisible: action.payload })
        }
        case signUpActions.SET_QUEUE_TIMING_WISE_ACTIVE: {
            return Object.assign({}, state, { queueIsDisabled: action.payload })
        }

        default:
            return state
    }
}

export default signUp
