import React from 'react'
import PropTypes from 'prop-types'

import { Select, Form, Popover } from 'antd'
import { PictureOutlined, UploadOutlined } from '@ant-design/icons'
import { countries } from '../../../../../utils/common'
import SuperAdminProfile from './SuperAdminProfile'
import SuperAdminStyle from '../../superAdmin.module.scss'
const { Option } = Select

index.propTypes = {
    form: PropTypes.object.isRequired,
    onFinish: PropTypes.func.isRequired,
    fileList: PropTypes.array.isRequired,
    setFileList: PropTypes.func.isRequired,
    logoPic: PropTypes.array.isRequired,
    setLogoPic: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
}
/**
 *
 * @param {*} form get from useForm antd
 * @param {*} onFinish get from parent for onsubmit function
 * @param {*} fileList images state
 * @param {*} setFileList set state function
 * @param {*} loading state loading for loader
 * @param {*} logoPic state for logo
 * @param {*} setLogoPic set state for logo
 * @returns jsx element branch Admin form
 */
export default function index({ form, onFinish, fileList, setFileList, loading, logoPic, setLogoPic }) {
    const codeBefore = (
        <Form.Item name="code" noStyle>
            <Select
                name="code"
                style={{
                    width: 100
                }}
                showSearch
                placeholder="Select a person"
                optionFilterProp="children"
                filterOption={(input, option) => {
                    return (
                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    )
                }}>
                {countries &&
                    countries.map((item, index) => {
                        return (
                            <Option title={item.name} key={index} value={item.code}>
                                {item.flag + ' ' + item.code}
                            </Option>
                        )
                    })}
            </Select>
        </Form.Item>
    )
    const countryCodeBefore = (
        <Form.Item name="country_code" noStyle>
            <Select
                name="code"
                style={{
                    width: 100
                }}
                showSearch
                placeholder="Select a person"
                optionFilterProp="children"
                filterOption={(input, option) => {
                    return (
                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    )
                }}>
                {countries &&
                    countries.map((item, index) => {
                        return (
                            <Option title={item.name} key={index} value={item.code}>
                                {item.flag + ' ' + item.code}
                            </Option>
                        )
                    })}
            </Select>
        </Form.Item>
    )
    const uploadButton = (
        <div>
            <Popover content={'Upload images'}>
                <PictureOutlined className={SuperAdminStyle.pictureIcon} />
            </Popover>
        </div>
    )
    const uploadLogoButton = (
        <div>
            <Popover content={'Upload Logo'}>
                <UploadOutlined className={SuperAdminStyle.pictureIcon} />
            </Popover>
        </div>
    )

    return (
        <div className={SuperAdminStyle.mainStyle}>
            <SuperAdminProfile
                form={form}
                onFinish={onFinish}
                codeBefore={codeBefore}
                countryCodeBefore={countryCodeBefore}
                uploadButton={uploadButton}
                uploadLogoButton={uploadLogoButton}
                fileList={fileList}
                setFileList={setFileList}
                logoPic={logoPic}
                setLogoPic={setLogoPic}
                loading={loading}
            />
        </div>
    )
}
