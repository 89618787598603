import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import Store from './redux'

// rootElement creting a root with application root ID
const rootElement = ReactDOM.createRoot(document.getElementById('root'))

/**
 * Application function
 * @returns Redux provider with Application
 */
const Application = () => {
    // render the store with the provider
    return (
        <Provider store={Store}>
            <App />
        </Provider>
    )
}
//Render the Entire Application
rootElement.render(<Application />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
