import React from 'react'
import PropTypes from 'prop-types'
import { Col, Form, Input, Button } from 'antd'
import AuthStyle from '../../../auth.module.scss'

ResetPassForm.propTypes = {
    form: PropTypes.object.isRequired,
    resetPassFun: PropTypes.func.isRequired,
    resetLoading: PropTypes.bool.isRequired
}
export default function ResetPassForm({ form, resetPassFun, resetLoading }) {
    return (
        <>
            <Col
                xl={{ span: 12, order: 1 }}
                lg={{ span: 12, order: 1 }}
                md={{ span: 24, order: 2 }}
                sm={{ span: 24, order: 2 }}
                xs={{ span: 24, order: 2 }}
                className={AuthStyle.mainForm}>
                <div className={AuthStyle.formContent}>
                    <div className={AuthStyle.headings}>
                        <h4 className={AuthStyle.welcome}>WELCOME!</h4>
                        <h1 className={AuthStyle.signIn}>Reset password</h1>
                    </div>
                    <Form
                        name="login-form"
                        labelCol={{ span: 12 }}
                        layout="vertical"
                        wrapperCol={{ span: 22 }}
                        form={form}
                        onFinish={resetPassFun}
                        autoComplete="off">
                        <Form.Item
                            name="password"
                            label="Enter your new password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your password'
                                },
                                {
                                    pattern: new RegExp(
                                        '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'
                                    ),
                                    message: 'Please enter strong password'
                                }
                            ]}>
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            name="cpass"
                            dependencies={['password']}
                            label="Confirm your new password"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!'
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve()
                                        }
                                        return Promise.reject(
                                            new Error('The two passwords that you entered do not match!')
                                        )
                                    }
                                })
                            ]}>
                            <Input.Password />
                        </Form.Item>
                        <Form.Item className={AuthStyle.button}>
                            <Button
                                disabled={resetLoading}
                                loading={resetLoading}
                                type="primary"
                                className={AuthStyle.btn}
                                htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Col>
        </>
    )
}
