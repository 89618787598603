import React from 'react'
import PropTypes from 'prop-types'
import { Row } from 'antd'
import SignInForm from './sign-in-form'
import RightSideBanner from './right-side-banner'
import AuthStyle from '../auth.module.scss'

CombineLogin.propTypes = {
    signIn: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    resetTrueOrFalse: PropTypes.bool.isRequired,
    setLoggedIn: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired
}
export default function CombineLogin({ signIn, loading, resetTrueOrFalse, userDetails, setLoggedIn }) {
    return (
        <>
            <Row className={AuthStyle.MainStyle}>
                <SignInForm
                    signIn={signIn}
                    mainLoading={loading}
                    resetTrueOrFalse={resetTrueOrFalse}
                    userDetails={userDetails}
                    setLoggedIn={setLoggedIn}
                />
                <RightSideBanner />
            </Row>
        </>
    )
}
