import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { Form, message } from 'antd'
import useAPICall from '../../../../../hooks/useAPICall'
import { getQueueData } from '../../../../queue-admin/manage-queue-component/api-helper/api-helper'
import LoginForm from './comp/LoginForm'
import ResetPassForm from './comp/ResetPassForm'

export default function index({ signIn, mainLoading, resetTrueOrFalse, userDetails, setLoggedIn }) {
    const { get } = useAPICall()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [resetPassForm] = Form.useForm()
    const [resetLoading, setResetLoading] = useState(false)

    const resetPassFun = async (values) => {
        try {
            let newPassword = values.password
            Auth.completeNewPassword(
                userDetails, // the Cognito User Object
                newPassword // the new password
            )
                .then((user) => {
                    const idToken = user.signInUserSession.idToken
                    localStorage.setItem('userEmail', idToken.payload['email'])
                    sessionStorage.setItem('username', idToken.payload.sub)
                    if (idToken.payload['custom:role'] === 'queue_admin') {
                        getQueueData(get, dispatch)
                    }
                    message.success('login successful')
                    sessionStorage.setItem('userType', idToken.payload['custom:role'])
                    localStorage.setItem('userType', idToken.payload['custom:role'])
                    localStorage.setItem('branch_id', idToken.payload['custom:branch_id'])
                    setLoggedIn(true)
                    setResetLoading(false)
                    navigate('/')
                })
                .catch((error) => {
                    console.log(error, 'error')
                    throw error?.message
                })
        } catch (error) {
            message.error(error)
        } finally {
            setResetLoading(false)
        }
    }
    return (
        <>
            {resetTrueOrFalse === true ? (
                <ResetPassForm form={resetPassForm} resetPassFun={resetPassFun} resetLoading={resetLoading} />
            ) : (
                <LoginForm signIn={signIn} loading={mainLoading} />
            )}
        </>
    )
}
