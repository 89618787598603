import React from 'react'
import PropTypes from 'prop-types'
import { Col, Form, Input, Button } from 'antd'
// import { useDispatch } from 'react-redux'
import {
    Link
    // useNavigate
} from 'react-router-dom'
import AuthStyle from '../../../auth.module.scss'
// import { onFinish } from './../../../api-helper'
LoginForm.propTypes = {
    signIn: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
}
export default function LoginForm({ signIn, loading }) {
    return (
        <>
            <Col
                xl={{ span: 12, order: 1 }}
                lg={{ span: 12, order: 1 }}
                md={{ span: 24, order: 2 }}
                sm={{ span: 24, order: 2 }}
                xs={{ span: 24, order: 2 }}
                className={AuthStyle.mainForm}>
                <div className={AuthStyle.formContent}>
                    <div className={AuthStyle.headings}>
                        <h4 className={AuthStyle.welcome}>WELCOME!</h4>
                        <h1 className={AuthStyle.signIn}>Sign in</h1>
                    </div>
                    <Form
                        name="login-form"
                        labelCol={{ span: 12 }}
                        layout="vertical"
                        wrapperCol={{ span: 22 }}
                        onFinish={signIn}
                        autoComplete="off">
                        <Form.Item
                            label="Enter your username"
                            name="username"
                            rules={[
                                { required: true, message: 'Please enter your username!' },
                                {
                                    pattern: new RegExp(
                                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    ),
                                    message: 'Please enter valid email id'
                                }
                            ]}>
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Enter your password"
                            name="password"
                            rules={[{ required: true, message: 'Please enter your password!' }]}>
                            <Input.Password />
                        </Form.Item>
                        <Link to="/auth/forgot-password" className={AuthStyle.ForgetLink}>
                            Forgot password ?
                        </Link>
                        <Form.Item className={AuthStyle.button}>
                            <Button
                                disabled={loading}
                                loading={loading}
                                type="primary"
                                className={AuthStyle.btn}
                                htmlType="submit">
                                Sign in
                            </Button>
                        </Form.Item>
                        <div className={AuthStyle.SignUpLink}>
                            New here? <Link to="/branch-admin-profile">Sign-up instead </Link>
                        </div>
                    </Form>
                </div>
            </Col>
        </>
    )
}
