// lazy is use for react suspense ui render
import { lazy } from 'react'

/**
 * COMMON ROUTES ------>
 */
const CommonNotification = lazy(() => import('../../components/common-comps/notifications'))
const CommonCustomerSupport = lazy(() => import('../../components/common-comps/cust-support-component'))
/* END --------------->*/

/**
 * Q-ADMIN ROUTES with lazy
 */
const QAdminManageQueue = lazy(() => import('../../components/queue-admin/index'))
const QAdminErrorComp = lazy(() => import('../../components/common-comps/Error-comp'))
const QAdminNoQueues = lazy(() =>
    import('../../components/queue-admin/manage-queue-component/CommonComponents/noqueues')
)
/* END --------------->*/

/**
 * BRANCH-ADMIN / BUSINESS PARTNER ROUTES with lazy
 */
const BAdminPaymentCancel = lazy(() =>
    import('../../components/common-comps/super-admin-profile/payment/PaymentCancell')
)
const BAdminMasterDirectory = lazy(() =>
    import('../../components/business-admin/master-dir-component/inner-comps/MasterDir')
)
const BAdminForm = lazy(() => import('../../components/common-comps/super-admin-profile/admin-profile'))
const BAdminSubscription = lazy(() => import('../../components/common-comps/super-admin-profile/subscription/index'))
const BAdminQlist = lazy(() => import('../../components/business-admin/q-list-component/inner-comps'))
const ViewBusinessProfile = lazy(() => import('../../components/business-admin/view-bus-p-component/inner-comps'))
const BAdminCreateQueue = lazy(() =>
    import('../../components/business-admin/master-dir-component/inner-comps/queues/CreateQueueMain')
)
const BAdminCreateParameter = lazy(() =>
    import(
        '../../components/business-admin/master-dir-component/inner-comps/queues/components/parameters/createparam/CreateParamIndex'
    )
)
/* END --------------->*/

/**
 * CONCORDE ADMIN ROUTES with lazy
 */
const CAdminCustomer = lazy(() => import('../../components/concorde-admin/customers/Customers'))
const CAdminSubsPackages = lazy(() => import('../../components/concorde-admin/master-directory/subs-packages'))
const CAdminApprovedPartners = lazy(() =>
    import('../../components/concorde-admin/Business-partner/ApprovedComponent/Approved')
)
const CAdminOnHoldPartners = lazy(() =>
    import('../../components/concorde-admin/Business-partner/onholdcomponent/OnHold')
)
const CAdminPendingPartners = lazy(() =>
    import(
        '../../components/concorde-admin/Business-partner/pending-vendor-request/pendingrequestcomponent/VendorRequest'
    )
)
const CAdminBusinesses = lazy(() =>
    import('../../components/concorde-admin/master-directory/businesses-component/Businesses')
)
const CAdminApprovedProfile = lazy(() =>
    import(
        '../../components/concorde-admin/Business-partner/ApprovedComponent/BusinessPartnerDetails/profilecomponent/ProfileApproved'
    )
)
const CAdminPendingProfiles = lazy(() =>
    import(
        '../../components/concorde-admin/Business-partner/pending-vendor-request/pendingprofilecomponent/PendingTabs'
    )
)
const CAdminOnHoldProfiles = lazy(() =>
    import('../../components/concorde-admin/Business-partner/onholdcomponent/commoncomp/OnHoldTabs')
)
const CAdminCustomerProfile = lazy(() => import('../../components/concorde-admin/customers/customerprofile/Profile'))
const CAdminC_S_CustomerProfile = lazy(() =>
    import('../../components/concorde-admin/customer-support/customer-queries/profile/CustomerProfile')
)
const CAdminCSupportBPartners = lazy(() =>
    import('../../components/concorde-admin/customer-support/cs-business-partner/BusinessPartners')
)
const CAdminC_S_BusinessProfile = lazy(() =>
    import('../../components/concorde-admin/customer-support/cs-business-partner/profile/BusinessProfile')
)
const CAdminRejectPartners = lazy(() =>
    import('../../components/concorde-admin/Business-partner/rejectComponent/reject/RejectBusiness')
)
const CAdminRejectProfiles = lazy(() =>
    import('../../components/concorde-admin/Business-partner/rejectComponent/common-comp/RejectTabs')
)
const CAdminCSupportCustomers = lazy(() =>
    import('../../components/concorde-admin/customer-support/customer-queries/Customer')
)
const CAdminDashboard = lazy(() => import('../../components/concorde-admin/dashboard'))
/* END --------------->*/

/**
 * QADMIN-ROUTES ----->
 */
export const qAdminRoutes = [
    { path: '/queue-admin/manage-queues/:index', name: 'Queue Admin Manage Queue', element: QAdminManageQueue },
    { path: 'customer-support', name: 'Queue Admin Customer Support', element: CommonCustomerSupport },
    { path: 'notification', name: 'Queue Admin Notification', element: CommonNotification },
    { path: 'manage-queue/error', name: 'Queue Admin ErrorComp', element: QAdminErrorComp },
    {
        path: '/queue-admin/manage-queue/no-queues-assigned',
        name: 'Queue Admin NoQueues',
        element: QAdminNoQueues
    }
]
/* END --------------->*/

/**
 * BRANCH ADMIN / BUSINESS PARTNER ROUTES  ---->
 */
export const businessAdminCreateParamIRoutes = [
    { path: '/create-param/:param', name: 'Branch Admin Create Param Index', element: BAdminCreateParameter },
    { path: '/edit-param/:param/:param_ind', name: 'Branch Admin Update Param Index', element: BAdminCreateParameter }
]
export const businessAdminSuperAdminRoutes = [
    { path: '/branch-admin-profile/:branchId', name: 'Branch Admin Super Admin', element: BAdminForm },
    { path: '/subscription-details', name: 'Branch Admin subscription-details', element: BAdminSubscription },
    { path: `/payment-cancel`, name: 'Branch Admin payment-cancel', element: BAdminPaymentCancel }
]
export const branchAdminRoutes = [
    { path: 'master-directory', name: 'Branch Admin Master Directory', element: BAdminMasterDirectory },
    { path: 'master-directory/create-queue', name: 'Branch Admin Create Queue Main', element: BAdminCreateQueue },
    {
        path: 'master-directory/update-queue/:queue_id',
        name: 'Branch Admin Create Queue Main',
        element: BAdminCreateQueue
    },
    { path: 'q-list/:q_listIndex', name: 'Branch Admin Queue list', element: BAdminQlist },
    { path: 'view-business-profile', name: 'Branch Admin View Business Profile', element: ViewBusinessProfile },
    { path: 'customer-support', name: 'Branch Admin Customer Support', element: CommonCustomerSupport },
    { path: 'notification', name: 'Branch Admin Notification', element: CommonNotification }
]
/* END --------------->*/

/**
 * CONCORDE ADMIN ROUTES  ---->
 */
export const concordeAdminRoutes = [
    { path: 'dashboard', name: 'Customer OTPs', element: CAdminDashboard },
    { path: 'master-directory/businesses', name: 'Concorde Admin Businesses', element: CAdminBusinesses },
    {
        path: 'master-directory/subscription-packages',
        name: 'Concorde Admin Subscription Packages',
        element: CAdminSubsPackages
    },
    { path: 'business-partner/approved', name: 'Concorde Admin Approved Partners', element: CAdminApprovedPartners },
    {
        path: 'business-partner/ProfileApproved/:partner_approved_id',
        name: 'Concorde Admin Approved Profile',
        element: CAdminApprovedProfile
    },
    { path: 'business-partner/pending', name: 'Concorde Admin Pending Requests', element: CAdminPendingPartners },
    {
        path: 'business-partner/pendingdetails/:partner_pending_id',
        name: 'Concorde Admin Pending Profile',
        element: CAdminPendingProfiles
    },
    { path: 'business-partner/on-hold', name: 'Concorde Admin OnHold Partners', element: CAdminOnHoldPartners },
    {
        path: 'business-partner/on-hold-tabs/:partner_on_hold_id',
        name: 'Concorde Admin On Hold Profiles',
        element: CAdminOnHoldProfiles
    },
    { path: 'business-partner/reject-business', name: 'Concorde Admin Reject Requests', element: CAdminRejectPartners },
    {
        path: 'business-partner/rejectdetails/:partner_reject_id',
        name: 'Concorde Admin Reject Profile',
        element: CAdminRejectProfiles
    },
    { path: 'customers', name: 'Concorde Admin Customers', element: CAdminCustomer },
    { path: 'customers/profile', name: 'Concorde Admin Customers Profile', element: CAdminCustomerProfile },
    {
        path: 'customer-support/c-customers',
        name: 'Concorde Admin C-Support Customers',
        element: CAdminCSupportCustomers
    },
    {
        path: 'customer-support/customer-profile/:customer_service_id',
        name: 'Concorde Admin C-Supprot Customer Profile',
        element: CAdminC_S_CustomerProfile
    },
    {
        path: 'customer-support/business-partners',
        name: 'Concorde Admin C-Support B-Partners',
        element: CAdminCSupportBPartners
    },
    {
        path: 'customer-support/business-profile/:business_service_id',
        name: 'Concorde Admin C-Support Business Profile',
        element: CAdminC_S_BusinessProfile
    }
]
/* END --------------->*/
