// branch, branchSubscription endpoint objects created in eps js
import { branch, branchSubscription } from '../../../../utils/eps'
// redux set / action functions
import {
    setBranchDetails,
    setBusinessAdminStatus,
    setPaymenntModal,
    setPaymenntDetails,
    setPaymentToken
} from '../../../../redux/actions/businessQue.action'

// message for error,  modal for warning from antd
import { message, Modal } from 'antd'

/**
 * addBranchSuperAdmin is function for create branch admin api call
 * @param {*} post method from useAPICall
 * @param {*} values values are the body sended to the api request
 * @returns eigther success or error
 */
export async function addBranchSuperAdmin(post, values) {
    try {
        const response = await post(branch.addBranch, values)
        return response
    } catch (err) {
        console.log('error in add branch')
        throw err
    }
}
/**
 * editBranchSuperAdmin is function for edit branch admin. api call
 * @param {*} patch method from useAPICall
 * @param {*} values values are the body sended to the api request
 * @returns eigther success or error
 */
export async function editBranchSuperAdmin(patch, values) {
    try {
        const response = await patch(branch.editBranch, values)
        return response
    } catch (err) {
        console.log('error in edit branch')
        throw err
    }
}

/**
 * viewBranchDetails get branch details
 * @param {*} get method from useAPICall
 * @returns eigther success or error
 */
export async function viewBranchDetails(get) {
    try {
        const response = await get(branch.viewBranch)
        return response
    } catch (err) {
        console.log('error in get branch details')
        throw err.message
    }
}
/**
 * getViewBranchDetails get branch details
 * @param {*} get method from useAPICall
 * @param {*} dispatch for set value in redux store
 * @param {*} setLoading set state function
 * @returns eigther success or error
 */
export async function getViewBranchDetails(get, dispatch, setLoading) {
    try {
        setLoading(true)
        const response = await viewBranchDetails(get)
        if (response.statusCode === 200) {
            dispatch(setBranchDetails(response.data))
            dispatch(setBusinessAdminStatus(response?.data?.branch_status))
            setLoading(false)
        } else {
            throw response.message
        }
    } catch (error) {
        message.error(error)
    } finally {
        setLoading(false)
    }
}

//SUBSCRIPTION APIS

/**
 * allSubscriptions get all subscription details
 * @param {*} get method from useAPICall
 * @returns eigther success or error
 */
export async function allSubscriptions(get) {
    try {
        const response = get(branchSubscription.getSubscriptions)
        return response
    } catch (error) {
        console.log('error in get subscription packages')
        throw error.message
    }
}

/**
 * subscriptionConfirm is function for confirm subscription api call
 * @param {*} post method from useAPICall
 * @param {*} data data are the body sended to the api request
 * @returns eigther success or error
 */
export async function subscriptionConfirm(post, data) {
    try {
        const response = post(branchSubscription.confirmSubscription, data)
        return response
    } catch (error) {
        console.log('error in confirm subscription package')
        throw error.message
    }
}

/**
 * subscriptionEdit is function for edit subscription package. api call
 * @param {*} patch method from useAPICall
 * @param {*} id package id
 * @param {*} data data are the body sended to the api request
 * @returns eigther success or error
 */
export async function subscriptionEdit(patch, id, data) {
    try {
        const response = patch(branchSubscription.editSubscriptions(id), data)
        return response
    } catch (error) {
        console.log('error in edit subscription package')
        throw error.message
    }
}

/**
 * getPaymentToken is function for get payment token api call
 * @param {*} post method from useAPICall
 * @param {*} data data are the body sended to the api request
 * @returns eigther success or error
 */
export async function getPaymentToken(post, data) {
    try {
        const response = post(branchSubscription.paymentToken, data)
        return response
    } catch (error) {
        console.log('error in get payment token')
        throw error.message
    }
}

/**
 * cancelPayment is function for cancel payment api call
 * @param {*} post method from useAPICall
 * @param {*} amount amount are the body sended to the api request
 * @returns eigther success or error
 */
export async function cancelPayment(post, amount) {
    try {
        const response = post(branchSubscription.paymentCancel, amount)
        return response
    } catch (error) {
        console.log('error in cancel payment')
        throw error.message
    }
}

/**
 * paymentToken get api call
 * @param {*} item choose package details
 * @param {*} post method from useAPICall
 * @param {*} setConfirmLoading set State function for loading state
 * @param {*} dispatch for set value in redux store
 */
export const paymentToken = async (item, post, setConfirmLoading, dispatch, navigate) => {
    try {
        setConfirmLoading(true)
        const response = await getPaymentToken(post, { amount: parseFloat(item.packageAmount) })
        if (response.statusCode === 200 && response.status === true && response.message == 'Successful.') {
            dispatch(setPaymenntModal(true))
            dispatch(setPaymenntDetails(item))
            dispatch(setPaymentToken(response.token))
        } else if (response.statusCode === 201 && response.status === true && response.message == 'Successful.') {
            dispatch(setPaymenntModal(true))
            dispatch(setPaymenntDetails(item))
            dispatch(setPaymentToken(response.token))
        } else if (response.statusCode === 200 && response.status === false) {
            Modal.warning({
                title: `Cannot choose this package you have ${response.activeQs} active queues.`,
                content: `If you want to get this package inactive or delete some queues.`,
                centered: true
            })
        } else if (response.statusCode === 201 && response.status === false) {
            Modal.warning({
                title: `Cannot choose this package you have ${response.activeQs} active queues.`,
                content: `If you want to get this package inactive or delete some queues.`,
                centered: true
            })
        } else if (response.statusCode === 203 && response.message == 'Invalid_amount') {
            navigate('/subscription-details')
        } else {
            throw response.message
        }
    } catch (error) {
        message.error(error)
    } finally {
        setConfirmLoading(false)
    }
}
