import React from 'react'
import SubscriptionIndex from './components'
import PayModal from './components/PayModal'
export default function index() {
    return (
        <>
            <SubscriptionIndex />
            <PayModal />
        </>
    )
}
