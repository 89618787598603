import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Form, message } from 'antd'
import useAPICall from '../../../../hooks/useAPICall'
import { addBranchSuperAdmin, editBranchSuperAdmin, getViewBranchDetails } from '../api-helper/api-helper'
import SpinnerComp from '../../spinner'
import SuperAdminIndex from './components'
import { editableCheck } from './components/editablecheck'

/**
 * get, loginPost, patch ----> methods get from useAPICall for api calls
 * onFinish ---> onsubmit form api call
 * @returns jsx element branch admin profile ui
 */
export default function index() {
    const { get, loginPost, patch } = useAPICall()
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { branchId } = useParams()
    const branchDetails = useSelector((state) => state.queueParam.branchDetails)
    const defaultCountry = useSelector((state) => state.signUp.defaultCountry)
    const [loading, setLoading] = useState(false)
    const [editLoading, setEditLoading] = useState(false)
    const [fileList, setFileList] = useState([])
    const [logoPic, setLogoPic] = useState([])

    const onFinish = async (values) => {
        if (values?.website_link && values?.website_link?.substr(0, 8) == 'https://') {
            let link = values?.website_link?.split('://')
            values.website_link = 'https://' + link[1]
        } else if (values?.website_link && values?.website_link?.substr(0, 7) == 'http://') {
            let link = values?.website_link?.split('://')
            values.website_link = 'https://' + link[1]
        } else if (values?.website_link) {
            let link = 'https://' + values.website_link
            values.website_link = link
        }

        if (fileList.length > 0) {
            const myFiles = [fileList].map((item) => {
                return item.map((image) => {
                    if (image.url) return image.url
                    else if (image?.response?.data) {
                        return image?.response?.data[0]?.Location
                    } else {
                        return image?.response?.[0].Location
                    }
                })
            })
            const myProfile = logoPic.map((item) => {
                if (item.url) return item.url
                else if (item?.response?.data) {
                    return item?.response?.data[0]?.Location
                } else {
                    return item?.response?.[0].Location
                }
            })

            try {
                setEditLoading(true)
                let temp = {
                    logo: myProfile ? myProfile[0] : '',
                    images: myFiles ? myFiles[0] : []
                }
                let response
                if (branchId) {
                    response = await editBranchSuperAdmin(patch, { ...values, ...temp })
                } else {
                    response = await addBranchSuperAdmin(loginPost, { ...values, ...temp })
                }
                if (
                    (response.statusCode === 201 && response.message == 'branch profile created') ||
                    (response.statusCode === 200 &&
                        response.message == 'updated successfully' &&
                        response.message !== 'Unauthorized')
                ) {
                    if (branchId) {
                        message.success('Successfully updated user')
                        navigate('/partner/view-business-profile')
                    } else {
                        message.success('Successfully created user')
                        navigate('/auth/sign-in')
                    }
                } else if (response.statusCode == 201 && response.message == 'user already exist') {
                    form.setFields([
                        {
                            name: 'user_email', // required
                            // value: '', //optional
                            errors: ['User already exists enter valid email id.']
                        }
                    ])
                    throw response.message
                } else if (response.status === 401 && response.message === 'Unauthorized') {
                    throw 'Request failed! your services temporarily off please contact admin'
                } else if (response.statusCode == 500) {
                    throw response.message
                } else if (
                    response.statusCode === 201 &&
                    response.message === `user with '${values.user_email}' has already signed up`
                ) {
                    form.setFields([
                        {
                            name: 'user_email', // required
                            // value: '', //optional
                            errors: ['User already exists enter valid email id.']
                        }
                    ])
                    throw response.message
                } else {
                    throw response.message
                }
                setEditLoading(false)
            } catch (error) {
                message.error(error)
            } finally {
                setEditLoading(false)
            }
        } else {
            message.error('Please upload branch images')
        }
    }
    useEffect(() => {
        if (branchId && Object.keys(branchDetails).length < 1) {
            getViewBranchDetails(get, dispatch, setLoading)
        }
    }, [branchDetails, branchId])

    useEffect(() => {
        editableCheck(branchId, branchDetails, setLogoPic, setFileList, form, defaultCountry)
    }, [branchId, branchDetails, defaultCountry])
    return (
        <>
            {loading ? (
                <SpinnerComp />
            ) : (
                <SuperAdminIndex
                    form={form}
                    onFinish={onFinish}
                    setFileList={setFileList}
                    fileList={fileList}
                    setLogoPic={setLogoPic}
                    logoPic={logoPic}
                    loading={editLoading}
                />
            )}
        </>
    )
}
