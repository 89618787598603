import { message } from 'antd'

import { notify, qList, queues } from '../../../../utils/eps'
import { setLoading, setQAdminQueues, setQueueListEmpty } from '../../../../redux/actions/qAdmin.action'

/**
 *
 * @param {*} get get custom function used for API calling
 * @param {*} emailId email id of a queue
 * @returns success of error
 */
export async function viewAllQueues(get, emailId) {
    try {
        const response = await get(queues.viewAssignQueues(emailId))
        return response
    } catch (err) {
        console.log(err, 'error in get all queues')
        throw err.message
    }
}

/**
 *
 * @param {*} get get custom function used for API calling
 * @param {*} id id of queue admin
 * @returns success or error
 */
export async function viewSingleQueue(get, id) {
    try {
        const response = await get(queues.viewSingle(id))
        return response
    } catch (err) {
        console.log('error in getting queue')
        throw err.message
    }
}

/**
 *
 * @param {*} get get custom function used for API calling
 * @param {*} id id of a queue admin
 * @param {*} search search value
 * @returns success or error
 */
export async function viewCurrentQueue(get, id, search) {
    try {
        const response = await get(qList.viewCurrent(id, 1, 10, search))
        return response
    } catch (error) {
        console.log('error in viewing current queue')
        throw error.message
    }
}

/**
 *
 * @param {*} post post custom function used for API calling
 * @param {*} values data of a customer to be added
 * @returns success or error
 */
export async function customerAddQueue(post, values) {
    try {
        const response = await post(qList.addToQueue, values)
        return response
    } catch (err) {
        console.log('error in add to queues')
        throw err.message
    }
}

/**
 *
 * @param {*} post post custom function used for API calling
 * @param {*} values data of the notification to be sent to all customers
 * @returns success or error
 */
export async function notifyAllCustomer(post, values) {
    try {
        const response = await post(notify.notificationAll, values)
        return response
    } catch (error) {
        console.log('error in notifying customer')
        throw error.message
    }
}

/**
 *
 * @param {*} post post custom function used for API calling
 * @param {*} values data of a notification to be sent to a customer
 * @returns success or error
 */
export async function notifyCustomerPush(post, values) {
    try {
        const response = await post(notify.push_notification, values)
        return response
    } catch (error) {
        console.log('error in notifying customer')
        throw error.message
    }
}

export async function notifyCustomerSMS(post, values) {
    try {
        const response = await post(notify.sms_notification, values)
        return response
    } catch (error) {
        console.log('error in notifying customer')
        throw error.message
    }
}

export async function notifyCustomerWhatsApp(post, values) {
    try {
        const response = await post(notify.whatsApp_notification, values)
        return response
    } catch (error) {
        console.log('error in notifying customer')
        throw error.message
    }
}
/**
 *
 * @param {*} patch patch custom function used for API calling
 * @param {*} id id of a customer to be removed
 * @param {*} values success or error
 * @returns
 */
export async function cusMoveOrRemove(patch, id, values) {
    try {
        const response = await patch(qList.moveOrRemove(id), values)
        return response
    } catch (err) {
        console.log('error in move queues')
        throw err.message
    }
}

/**
 *
 * @param {*} get get custom function used for API calling
 * @param {*} number phone number of a customer
 * @returns success or error
 */
export async function checkCustomer(get, number) {
    try {
        const response = await get(qList.checkUser(number))
        return response
    } catch (error) {
        console.log('error in cheching customer')
        throw error.message
    }
}

/**
 *
 * @param {*} put put customer function used for API calling
 * @param {*} values token for firebase
 * @returns success or error
 */
export async function getFcmToken(put, values) {
    try {
        const response = await put(queues.forFcmToken, values)
        return response
    } catch (err) {
        console.log('error in add to queues')
        throw err.message
    }
}

/**
 *
 * @param {*} get get customer function used for API
 * @param {*} dispatch useDispatch from redux
 */
export const getQueueData = async (get, dispatch) => {
    let emailId = localStorage.getItem('userEmail')
    try {
        dispatch(setLoading(true))
        const response = await viewAllQueues(get, emailId)
        if (response.statusCode === 200 && response.message !== 'Unauthorized') {
            dispatch(setLoading(false))
            dispatch(setQAdminQueues(response))

            if (response.queue && response.queue.length < 1) {
                dispatch(setQueueListEmpty(true))
            }
        } else if (response.statusCode == 500) {
            throw response.message
        } else if (response.status === 401 && response.message === 'Unauthorized') {
            throw 'Failed to get all Queues, admin not approved'
        } else {
            throw response.message
        }
    } catch (error) {
        message.error(error)
    } finally {
        dispatch(setLoading(false))
    }
}
