// SIGN_UP_TYPES ---->
const SET_IS_SIGNED_IN = 'SET_IS_SIGNED_IN'
const SET_IS_USER_TYPE = 'SET_IS_USER_TYPE'
const SET_LOGIN_EMAIL = 'SET_LOGIN_EMAIL'
const SET_LOG_OUT_MODAL = 'SET_LOG_OUT_MODAL'
const SET_DEFAULT_STATE = 'SET_DEFAULT_STATE'
const SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT'
const SET_QR_CODE_MODAL_STATE = 'SET_QR_CODE_MODAL_STATE'
const SET_DEFAULT_CONTRY_CODE = 'SET_DEFAULT_CONTRY_CODE'
const SET_QUEUE_TIMING_WISE_ACTIVE = 'SET_QUEUE_TIMING_WISE_ACTIVE'

/**
 * signUpActions is object to set all types in one object
 */
export const signUpActions = {
    SET_IS_SIGNED_IN,
    SET_IS_USER_TYPE,
    SET_LOGIN_EMAIL,
    SET_LOG_OUT_MODAL,
    SET_DEFAULT_STATE,
    SET_NOTIFICATION_COUNT,
    SET_QR_CODE_MODAL_STATE,
    SET_DEFAULT_CONTRY_CODE,
    SET_QUEUE_TIMING_WISE_ACTIVE
}

//  BUSINESS ADMIN TYPES ---->
const SET_ADD_QUEUE_FORM_DETAILS = 'SET_ADD_QUEUE_FORM_DETAILS'
const SET_O_ADMIN_FORM_DETAILS = 'SET_O_ADMIN_FORM_DETAILS'
const SET_QUEUE_PARAM_OR_ACTION = 'SET_QUEUE_PARAM_OR_ACTION'
const SET_QUEUE_PARAM_ADD = 'SET_QUEUE_PARAM_ADD'
const SET_QUEUE_PARAM_EDIT = 'SET_QUEUE_PARAM_EDIT'
const SET_QUEUE_PARAM_DELETE = 'SET_QUEUE_PARAM_DELETE'
const SET_QUEUE_ACTION_ADD = 'SET_QUEUE_ACTION_ADD'
const SET_QUEUE_ACTION_EDIT = 'SET_QUEUE_ACTION_EDIT'
const SET_QUEUE_ACTION_DELETE = 'SET_QUEUE_ACTION_DELETE'
const SET_QUEUE_NOTIFICATION_ADD = 'SET_QUEUE_NOTIFICATION_ADD'
const SET_QUEUE_NOTIFICATION_EDIT = 'SET_QUEUE_NOTIFICATION_EDIT'
const SET_QUEUE_NOTIFICATION_DELETE = 'SET_QUEUE_NOTIFICATION_DELETE'
const SET_CLEAR_QUEUE_FORM_DETAILS = 'SET_CLEAR_QUEUE_FORM_DETAILS'
const SET_EDIT_ID_FORM = 'SET_EDIT_ID_FORM'
const SET_ALL_QUEUE_DETAILS = 'SET_ALL_QUEUE_DETAILS'
const SET_QUEUE_PAGINATION = 'SET_QUEUE_PAGINATION'
const SET_PARAM_TRUE_FALSE = 'SET_PARAM_TRUE_FALSE'
const SET_BUSINESS_ADMIN_STATUS = 'SET_BUSINESS_ADMIN_STATUS'
const SET_BRANCH_DETAILS = 'SET_BRANCH_DETAILS'
const SET_NOT_EDIT_ID = 'SET_NOT_EDIT_ID'
const SET_NOT_EDIT_DATA = 'SET_NOT_EDIT_DATA'
const SET_SUBSCRIPTION_WISE_QUEUE = 'SET_SUBSCRIPTION_WISE_QUEUE'
const SET_EDITABLE_STATE = 'SET_EDITABLE_STATE'
const SET_SUBSCRIPTION_DETAILS = 'SET_SUBSCRIPTION_DETAILS'
const SET_PAYMENT_MODAL_STATE = 'SET_PAYMENT_MODAL_STATE'
const SET_PAYMENT_DETAILS_STATE = 'SET_PAYMENT_DETAILS_STATE'
const SET_PAYMENT_TOKEN_STATE = 'SET_PAYMENT_TOKEN_STATE'
const SET_ISPAYMENT_EXPIRE = 'SET_ISPAYMENT_EXPIRE'
const SET_EXPIRES_STATE = 'SET_EXPIRES_STATE'
/**
 * BusinessQueAction is object to set all types in one object
 */
export const BusinessQueAction = {
    SET_ADD_QUEUE_FORM_DETAILS,
    SET_O_ADMIN_FORM_DETAILS,
    SET_QUEUE_PARAM_OR_ACTION,
    SET_QUEUE_PARAM_ADD,
    SET_QUEUE_PARAM_EDIT,
    SET_QUEUE_PARAM_DELETE,
    SET_QUEUE_ACTION_ADD,
    SET_QUEUE_ACTION_EDIT,
    SET_QUEUE_ACTION_DELETE,
    SET_QUEUE_NOTIFICATION_ADD,
    SET_QUEUE_NOTIFICATION_EDIT,
    SET_QUEUE_NOTIFICATION_DELETE,
    SET_CLEAR_QUEUE_FORM_DETAILS,
    SET_EDIT_ID_FORM,
    SET_ALL_QUEUE_DETAILS,
    SET_QUEUE_PAGINATION,
    SET_PARAM_TRUE_FALSE,
    SET_BRANCH_DETAILS,
    SET_NOT_EDIT_ID,
    SET_NOT_EDIT_DATA,
    SET_BUSINESS_ADMIN_STATUS,
    SET_SUBSCRIPTION_WISE_QUEUE,
    SET_EDITABLE_STATE,
    SET_SUBSCRIPTION_DETAILS,
    SET_PAYMENT_MODAL_STATE,
    SET_PAYMENT_DETAILS_STATE,
    SET_PAYMENT_TOKEN_STATE,
    SET_ISPAYMENT_EXPIRE,
    SET_EXPIRES_STATE
}

// CONCORDE ADMIN TYPES ---->
const SET_FETCH_PENDING_DETAILS = 'SET_FETCH_PENDING_DETAILS'
const SET_PENDING_PROFILE = 'SET_PENDING_PROFILE'
const SET_BUSINESS_SUBSCRIPTION = 'SET_BUSINESS_SUBSCRIPTION'
const SET_FETCH_APPROVED_DETAILS = ' SET_FETCH_APPROVED_DETAILS'
const SET_FETCH_REJECTED_DETAILS = ' SET_FETCH_REJECTED_DETAILS'
const SET_REJECTED_PROFILE = ' SET_REJECTED_PROFILE'
const SET_APPROVED_PROFILE = ' SET_APPROVED_PROFILE'
const SET_FETCH_QUEUE_LIST = 'SET_FETCH_QUEUE_LIST'
const SET_CUSTOMER_DETAILS = 'SET_CUSTOMER_DETAILS'
const SET_CUSTOMER_PROFILE = 'SET_CUSTOMER_PROFILE'
const SET_CUSTOMER_QUEUE_DETAILS = 'SET_CUSTOMER_QUEUE_DETAILS'
const SET_APPROVED_CUSTOMER_QUEUE = 'SET_APPROVED_CUSTOMER_QUEUE'
const SET_BUSINESS_PARTNER_ON_HOLD = 'SET_BUSINESS_PARTNER_ON_HOLD'
const SET_BUSINESS_PARTNER_ON_HOLD_PROFILE = 'SET_BUSINESS_PARTNER_ON_HOLD_PROFILE'
const SET_CUSTOMER_SUPPORT_PROFILE = 'SET_CUSTOMER_SUPPORT_PROFILE'
const SET_CS_BUSINESS_PARTNER_PROFILE = 'SET_CS_BUSINESS_PARTNER_PROFILE'
const SET_SUB_PACKAGE_EDIT = 'SET_SUB_PACKAGE_EDIT'
const SET_SUB_PACKAGE_EDIT_DATA = 'SET_SUB_PACKAGE_EDIT_DATA'

/**
 * BusinessQueAction is object to set all types in one object
 */
export const BusinessPartnerAction = {
    SET_FETCH_PENDING_DETAILS,
    SET_PENDING_PROFILE,
    SET_BUSINESS_SUBSCRIPTION,
    SET_FETCH_APPROVED_DETAILS,
    SET_APPROVED_PROFILE,
    SET_FETCH_QUEUE_LIST,
    SET_APPROVED_CUSTOMER_QUEUE,
    SET_BUSINESS_PARTNER_ON_HOLD,
    SET_BUSINESS_PARTNER_ON_HOLD_PROFILE,
    SET_FETCH_REJECTED_DETAILS,
    SET_REJECTED_PROFILE,
    SET_SUB_PACKAGE_EDIT,
    SET_SUB_PACKAGE_EDIT_DATA
}

/**
 * customersAction is object to set all types in one object
 */
export const customersAction = {
    SET_CUSTOMER_DETAILS,
    SET_CUSTOMER_PROFILE,
    SET_CUSTOMER_QUEUE_DETAILS,
    SET_CUSTOMER_SUPPORT_PROFILE,
    SET_CS_BUSINESS_PARTNER_PROFILE
}

//  QUEUE ADMIN TYPES ---->
const SET_Q_ADMIN_QUEUE_DETAILS = 'SET_Q_ADMIN_QUEUE_DETAILS'
const SET_Q_ADMIN_QUEUE_LOADING = 'SET_Q_ADMIN_QUEUE_LOADING'
const SET_MANAGE_QUEUE_EMPTY = 'SET_MANAGE_QUEUE_EMPTY'

/**
 * QueueAdminAction is object to set all types in one object
 */
export const QueueAdminAction = {
    SET_Q_ADMIN_QUEUE_DETAILS,
    SET_Q_ADMIN_QUEUE_LOADING,
    SET_MANAGE_QUEUE_EMPTY
}
