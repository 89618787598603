export function editableCheck(branchId, branchDetails, setLogoPic, setFileList, form, defaultCountry) {
    if (branchId) {
        if (branchDetails && branchDetails.branch_logo) {
            const files = [branchDetails.branch_logo].map((item, index) => {
                return { url: `${item}`, uid: `${index}`, status: 'done', name: 'logo' + index }
            })
            setLogoPic(files)
        } else {
            setLogoPic([])
        }
        if (branchDetails && branchDetails.branch_images) {
            if (Array.isArray(branchDetails.branch_images[0])) {
                const files = branchDetails.branch_images[0].map((item, index) => {
                    return { url: `${item}`, uid: `${index}`, status: 'done', name: 'image' + index }
                })
                setFileList(files)
            } else {
                const files = branchDetails.branch_images.map((item, index) => {
                    return { url: `${item}`, uid: `${index}`, status: 'done', name: 'image' + index }
                })
                setFileList(files)
            }
        } else {
            setFileList([])
        }
        form.setFieldsValue({ code: branchDetails.user_country_code })
        form.setFieldsValue({ country_code: branchDetails.branch_country_code })
        form.setFieldsValue({ user_name: branchDetails.user_name })
        form.setFieldsValue({ user_mobile: branchDetails.user_phone_number })
        form.setFieldsValue({ user_email: branchDetails.user_email_id })
        form.setFieldsValue({ job_role: branchDetails.user_job_role })
        form.setFieldsValue({ name: branchDetails.branch_name })
        form.setFieldsValue({ phone_number: branchDetails.branch_phone_number })
        form.setFieldsValue({ email: branchDetails.branch_email })
        form.setFieldsValue({ address: branchDetails.branch_address })
        form.setFieldsValue({ description: branchDetails.branch_description })
        form.setFieldsValue({ website_link: branchDetails.branch_website_link })
    } else {
        form.setFieldsValue({ code: defaultCountry.country_calling_code ? defaultCountry.country_calling_code : '' })
        form.setFieldsValue({
            country_code: defaultCountry.country_calling_code ? defaultCountry.country_calling_code : ''
        })
        form.setFieldsValue({ user_name: '' })
        form.setFieldsValue({ user_mobile: '' })
        form.setFieldsValue({ user_email: '' })
        form.setFieldsValue({ job_role: '' })
        form.setFieldsValue({ name: '' })
        form.setFieldsValue({ phone_number: '' })
        form.setFieldsValue({ email: '' })
        form.setFieldsValue({ address: '' })
        form.setFieldsValue({ description: '' })
        form.setFieldsValue({ website_link: '' })
    }
}
