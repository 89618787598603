import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Form, message } from 'antd'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Auth } from 'aws-amplify'
import ForgotForm from './comp/ForgotForm'
import RightSideBanner from '../right-side-banner'
import SetNewPassword from './comp/SetNewPassword'
import AuthStyle from '../../auth.module.scss'

ForgotPassIndex.propTypes = {
    form: PropTypes.object.isRequired,
    sendCode: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    newPass: PropTypes.bool.isRequired,
    setNewPass: PropTypes.func.isRequired
}
export default function ForgotPassIndex({ form, sendCode, loading, newPass, setNewPass }) {
    const [newForm] = Form.useForm()
    const navigate = useNavigate()
    const name = useSelector((state) => state.signUp.loginEmail)
    const [passLoading, setPassLoading] = useState(false)

    const newPasswordSubmit = async (values) => {
        const verCode = values.code
        const password = values.new_pass
        const username = name
        try {
            setPassLoading(true)
            const response = await Auth.forgotPasswordSubmit(username, verCode, password)
            if (response === 'SUCCESS') {
                message.success('Password update successfully')
                setPassLoading(false)
                setNewPass(false)
                navigate('/auth/sign-in')
                newForm.resetFields()
            } else if (response.__type === 'ExpiredCodeException') {
                throw response.message
            } else if (response.__type === 'LimitExceededException') {
                throw response.message
            } else {
                throw response.message
            }
        } catch (error) {
            message.error(error.message)
        } finally {
            setPassLoading(false)
        }
    }

    return (
        <>
            <Row className={AuthStyle.MainStyle}>
                {newPass === true ? (
                    <SetNewPassword newForm={newForm} passLoading={passLoading} newPasswordSubmit={newPasswordSubmit} />
                ) : (
                    <ForgotForm form={form} loading={loading} sendCode={sendCode} />
                )}
                <RightSideBanner />
            </Row>
        </>
    )
}
