// Auth from aws-amplify is given by library
import { Auth } from 'aws-amplify'

/**
 * getAccessToken is function call for get acessToken from aws
 *
 * @param {*} withPayload this value eighter null or a boolean
 * @returns accessToken for api calls
 */
export const getAccessToken = async (withPayload = null) => {
    const session = await Auth.currentSession()
    if (withPayload) {
        // return session.idToken
        return session.accessToken
    } else {
        return session.getIdToken().getJwtToken()
    }
}

/**
 * isLoggedIn function  are the check is current user is logged in or not
 * @returns eigther true or false
 */
export const isLoggedIn = async () => {
    try {
        await Auth.currentAuthenticatedUser()
        return true
    } catch (error) {
        return false
    }
}
