import React, { lazy, Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import SpinnerComp from '../../components/common-comps/spinner'
import {
    branchAdminRoutes,
    businessAdminCreateParamIRoutes,
    businessAdminSuperAdminRoutes,
    concordeAdminRoutes,
    qAdminRoutes
} from './routes'

//MAIN COMMON ROUTES -->
const Layout = lazy(() => import('../../components/common-comps/layout'))
const SuspenseWrapper = lazy(() => import('./SuspenseWrapper'))
// END ------>

/**
 * ProtectedRoutes component write for All accessible routes to navigate if user logged in
 * whichAdmin -----> is getting user type from localStorage
 * queueAdminSideBar -----> getting all queues list for check sidebar queues
 * noQueues -----> getting eigther true or false for check set routes
 * @returns JSX Element of All routes
 */
export default function ProtectedRoutes() {
    const whichAdmin = localStorage.getItem('userType')
    const queueAdminSideBar = useSelector((state) => state.QueueAdminR.qAdminSideBarQueues)
    const noQueues = useSelector((state) => state.QueueAdminR.emptyManageQueues)
    return (
        <Suspense fallback={<SpinnerComp />}>
            <Routes>
                {whichAdmin === 'queue_admin' ? ( // checking on localStorage whichAdmin is queue_admin or not
                    <>
                        {!!queueAdminSideBar &&
                            queueAdminSideBar.length > 0 && ( // checking on redux assigned queues are exist or not
                                <Route
                                    path="/"
                                    element={
                                        <Suspense fallback={<SpinnerComp />}>
                                            {!!queueAdminSideBar && queueAdminSideBar.length > 0 ? ( // this condition for another TIME checking on redux assigned queues are exist or not
                                                <>
                                                    <Navigate
                                                        to={`/queue-admin/manage-queues/${
                                                            queueAdminSideBar && queueAdminSideBar.length > 0
                                                                ? queueAdminSideBar[0].queue_id
                                                                : 1
                                                        }`}
                                                    />
                                                </>
                                            ) : (
                                                <Navigate to={`/queue-admin/manage-queue/error`} />
                                            )}
                                        </Suspense>
                                    }
                                />
                            )}
                        {!!queueAdminSideBar &&
                            noQueues === true && ( // checking on redux assigned queues are notexist or noQueues == true then navigate no-queues route
                                <Route
                                    path="/"
                                    element={
                                        <Suspense fallback={<SpinnerComp />}>
                                            <Navigate to={`/queue-admin/manage-queue/no-queues-assigned`} />
                                        </Suspense>
                                    }
                                />
                            )}
                        <Route path="/queue-admin" element={<SuspenseWrapper component={<Layout />} />}>
                            {qAdminRoutes.map((route, idx) => {
                                return (
                                    route.element && (
                                        <Route
                                            path={route.path}
                                            key={idx}
                                            element={<SuspenseWrapper component={<route.element />} />}
                                        />
                                    )
                                )
                            })}
                        </Route>
                    </>
                ) : whichAdmin === 'branch_admin' ? ( //checking on localStorage whichAdmin is branch admin or not
                    <>
                        {businessAdminCreateParamIRoutes.map((route, idx) => {
                            return (
                                route.element && (
                                    <Route
                                        path={route.path}
                                        key={idx}
                                        element={<SuspenseWrapper component={<route.element />} />}
                                    />
                                )
                            )
                        })}
                        {businessAdminSuperAdminRoutes.map((route, idx) => {
                            return (
                                route.element && (
                                    <Route
                                        path={route.path}
                                        key={idx}
                                        element={<SuspenseWrapper component={<route.element />} />}
                                    />
                                )
                            )
                        })}
                        <Route path="/" element={<Navigate to="/partner/master-directory" />} />
                        <Route path="/partner" element={<SuspenseWrapper component={<Layout />} />}>
                            {branchAdminRoutes.map((route, idx) => {
                                return (
                                    route.element && (
                                        <Route
                                            path={route.path}
                                            key={idx}
                                            element={<SuspenseWrapper component={<route.element />} />}
                                        />
                                    )
                                )
                            })}
                        </Route>
                    </>
                ) : whichAdmin === 'super_admin' ? ( //checking on localStorage whichAdmin is super_admin or not for adding concorde route
                    <>
                        <Route path="/" element={<Navigate to="/concorde/master-directory/businesses" />} />
                        <Route path="/concorde" element={<SuspenseWrapper component={<Layout />} />}>
                            {concordeAdminRoutes.map((route, idx) => {
                                return (
                                    route.element && (
                                        <Route
                                            path={route.path}
                                            key={idx}
                                            element={<SuspenseWrapper component={<route.element />} />}
                                        />
                                    )
                                )
                            })}
                        </Route>
                    </>
                ) : null}
            </Routes>
        </Suspense>
    )
}
