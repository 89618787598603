// BusinessPartnerAction, signUpActions ,customersAction are the types for written in types.js

import { BusinessPartnerAction, signUpActions, customersAction } from './../actions/types'

/**
 * initialState is object to define all are the initial states
 */
const initialState = {
    pendingDetails: {},
    pendingProfile: {},
    approvedDetails: [],
    approvedProfile: {},
    queueDetails: [],
    customerDetails: [],
    customerProfile: {},
    customersQueue: [],
    approvedCustomerQueue: [],
    businessPartnerOnHold: [],
    businessPartnerOnHoldProfile: {},
    customerSupportProfile: [],
    businessPartnerProfile: [],
    rejectedDetails: [],
    rejectedProfile: [],
    subPackageEditModal: false,
    subPackageEditData: {}
}

/**
 * businessPartner function are the reducer function to return the what state is changes to check that and update that
 * @param {*} state state is the values of default initialState
 * @param {*} action action is the object have type and payload
 * @returns it returns eigther state what should update or default state
 */
function businessPartner(state = initialState, action) {
    //checking action.type is sended type and set action.payload

    switch (action.type) {
        case BusinessPartnerAction.SET_FETCH_PENDING_DETAILS: {
            return Object.assign({}, state, { pendingDetails: action.payload })
        }
        case BusinessPartnerAction.SET_PENDING_PROFILE: {
            return Object.assign({}, state, { pendingProfile: action.payload })
        }
        case BusinessPartnerAction.SET_FETCH_APPROVED_DETAILS: {
            return Object.assign({}, state, { approvedDetails: action.payload })
        }
        case BusinessPartnerAction.SET_FETCH_REJECTED_DETAILS: {
            return Object.assign({}, state, { rejectedDetails: action.payload })
        }
        case BusinessPartnerAction.SET_APPROVED_PROFILE: {
            return Object.assign({}, state, { approvedProfile: action.payload })
        }
        case BusinessPartnerAction.SET_FETCH_QUEUE_LIST: {
            return Object.assign({}, state, { queueDetails: action.payload })
        }
        case BusinessPartnerAction.SET_APPROVED_CUSTOMER_QUEUE: {
            return Object.assign({}, state, { approvedCustomerQueue: action.payload })
        }
        case BusinessPartnerAction.SET_BUSINESS_PARTNER_ON_HOLD: {
            return Object.assign({}, state, { businessPartnerOnHold: action.payload })
        }
        case BusinessPartnerAction.SET_BUSINESS_PARTNER_ON_HOLD_PROFILE: {
            return Object.assign({}, state, { businessPartnerOnHoldProfile: action.payload })
        }
        case BusinessPartnerAction.SET_REJECTED_PROFILE: {
            return Object.assign({}, state, { rejectedProfile: action.payload })
        }
        case customersAction.SET_CUSTOMER_DETAILS: {
            return Object.assign({}, state, { customerDetails: action.payload })
        }
        case customersAction.SET_CUSTOMER_PROFILE: {
            return Object.assign({}, state, { customerProfile: action.payload })
        }
        case customersAction.SET_CUSTOMER_QUEUE_DETAILS: {
            return Object.assign({}, state, { customersQueue: action.payload })
        }
        case customersAction.SET_CUSTOMER_SUPPORT_PROFILE: {
            return Object.assign({}, state, { customerSupportProfile: action.payload })
        }
        case customersAction.SET_CS_BUSINESS_PARTNER_PROFILE: {
            return Object.assign({}, state, { businessPartnerProfile: action.payload })
        }
        case BusinessPartnerAction.SET_SUB_PACKAGE_EDIT: {
            return Object.assign({}, state, { subPackageEditModal: action.payload })
        }
        case BusinessPartnerAction.SET_SUB_PACKAGE_EDIT_DATA: {
            return Object.assign({}, state, { subPackageEditData: action.payload })
        }
        case signUpActions.SET_DEFAULT_STATE: {
            return initialState
        }
        default:
            return state
    }
}

export default businessPartner
