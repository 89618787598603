/**
 * this Logger class use for all api calls set console.logs for test the API's response
 */
class Logger {
    static error(...messages) {
        console.log('Error:', ...messages)
        if (process.env.NODE_ENV === 'production') return
    }

    static debug(...messages) {
        console.log('Debug:', ...messages)
        if (process.env.NODE_ENV === 'production') return
    }

    static info(...messages) {
        console.log('Info:', ...messages)
        if (process.env.NODE_ENV === 'production') return
    }
}

export default Logger
