// /* eslint-disable no-unused-vars */

import Logger from '../utils/logger'
import { getAccessToken } from '../awsGetToken'
import { message } from 'antd'
import Axios from 'axios'
/**
 * HTTP_METHOD ---- creting object of all the type of methods of api call
 */
const HTTP_METHOD = Object.freeze({
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    PATCH: 'PATCH',
    DELETE: 'DELETE'
})
/**
 * getAccessTokenFromAWS call during api call if accesstoken is expired
 * @returns the jwtToken of aws
 */
const getAccessTokenFromAWS = async () => {
    let id = await getAccessToken(true)
    return id.jwtToken
}
/**
 * useAPICall custom api call function in one file
 * @returns all api response if succes or error and also http method fuctions
 */
const useAPICall = () => {
    const token = false
    /**
     * genericHeaders is function for set the Request header
     * @returns headers
     */
    const genericHeaders = () => {
        const headers = { 'Content-Type': 'application/json' }
        if (token) headers.Authorization = `Bearer ${token}`
        return headers
    }

    /**
     * makeRequest is the function for make a request/api call of all methods
     * @param {*} url is the url for what are pass in methods
     * @param {*} method is method for what are the method set in method function
     * @param {*} data is the body of what should we set during api call
     * @param {*} withCredentials is the request needs some extra credentials
     * @param {*} idToken is the accessToken needs to api call
     * @param {*} timeout timeout for time duration whenever api call cancelled
     * @returns the response during api what are success or error
     */
    const makeRequest = async (url, method, data, withCredentials, idToken, timeout = 30000) => {
        let headers = { 'content-type': 'application/json' }
        if (idToken) headers.Authorization = `Bearer ${idToken}`
        // if (idToken) headers.Authorization = idToken

        try {
            // console.log(url, method, data, withCredentials, headers, timeout)
            const response = await Axios({
                data,
                headers,
                method,
                timeout,
                url,
                withCredentials
            })
            if (Array.isArray(response?.data)) {
                let data = Object.assign({}, { statusCode: response.status })
                return { ...data, data: response?.data }
            } else {
                return Object.assign({}, response.data, { statusCode: response.status })
            }
        } catch (error) {
            if (error.code === 'ERR_NETWORK') {
                message.error('No internet connection')
            }
            if (error.response?.data?.statusCode == 500) {
                let newErr = error?.response.data
                throw newErr
            }
            Logger.error(url, error.response, error.response.status)
            if (error.message === 'The user aborted a request.') {
                message.error('Your internet connection is slow please try again later')
            }
            throw error
        }
    }
    /* for first before login apis call ------>*/
    /**
     * loginGet before log in functions
     * @param {*} ep what are the endpoint pass
     * @param {*} include this are the credetials
     * @param {*} headers this is the header set in genericHeaders()
     * @returns makeRequest function call with this parameters
     */
    const loginGet = async (ep, include = false, headers = genericHeaders()) => {
        return makeRequest(ep, HTTP_METHOD.GET, null, include, headers)
    }
    /**
     * loginPost function written for before login post method call
     * @param {*} ep what are the endpoint pass
     * @param {*} body what are the body needs to be send in post request
     * @param {*} include this are the credetials
     * @param {*} headers this is the header set in genericHeaders()
     * @returns makeRequest function call with this parameters
     */
    const loginPost = async (ep, body, include = false, headers = genericHeaders()) => {
        return makeRequest(ep, HTTP_METHOD.POST, JSON.stringify(body), include, headers)
    }
    /* for first before login apis call -------->*/

    // change needs here ------>
    /**
     * get function write for what are the get api calls overall the app
     * @param {*} ep ep what are the endpoint pass
     * @param {*} include this are the credetials
     * @returns makeRequest function call with the sended parameters
     */
    const get = async (ep, include = false) => {
        return makeRequest(ep, HTTP_METHOD.GET, null, include, await getAccessTokenFromAWS())
    }
    /**
     * post function written for all over post api request send
     * @param {*} ep ep what are the endpoint pass
     * @param {*} body what are the body needs to be send in post request
     * @param {*} include this are the credetials
     * @returns makeRequest function call with the sended parameters
     */
    const post = async (ep, body, include = false) => {
        return makeRequest(ep, HTTP_METHOD.POST, JSON.stringify(body), include, await getAccessTokenFromAWS())
    }

    /**
     * put function written for allover put api request api calls
     * @param {*} ep ep what are the endpoint pass
     * @param {*} body what are the body needs to be send in post request
     * @param {*} include this are the credetials
     * @returns makeRequest function call with the sended parameters
     */
    const put = async (ep, body, include = false) => {
        return makeRequest(ep, HTTP_METHOD.PUT, JSON.stringify(body), include, await getAccessTokenFromAWS())
    }
    /**
     * patch function written for allover the application patch request api calls
     * @param {*} ep ep what are the endpoint pass
     * @param {*} body what are the body needs to be send in post request
     * @param {*} include this are the credetials
     * @returns makeRequest function call with the sended parameters
     */
    const patch = async (ep, body, include = false) => {
        return makeRequest(ep, HTTP_METHOD.PATCH, JSON.stringify(body), include, await getAccessTokenFromAWS())
    }
    /**
     * del function written for the allover the application delete requests api calls
     * @param {*} ep ep what are the endpoint pass
     * @param {*} body  what are the body needs to be send in post request
     * @param {*} include this are the credetials
     * @returns makeRequest function call with the sended parameters
     */
    const del = async (ep, body, include = false) => {
        return makeRequest(ep, HTTP_METHOD.DELETE, JSON.stringify(body), include, await getAccessTokenFromAWS())
    }

    // custom hook returns value
    return { get, loginGet, post, loginPost, put, patch, del }
}

export default useAPICall
