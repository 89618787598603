import React from 'react'
import PropTypes from 'prop-types'
import { Col, Form, Input, Button } from 'antd'
import AuthStyle from '../../../auth.module.scss'

ForgotForm.propTypes = {
    form: PropTypes.object.isRequired,
    sendCode: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
}
export default function ForgotForm({ form, loading, sendCode }) {
    return (
        <>
            <Col
                xl={{ span: 12, order: 1 }}
                lg={{ span: 12, order: 1 }}
                md={{ span: 24, order: 2 }}
                sm={{ span: 24, order: 2 }}
                xs={{ span: 24, order: 2 }}
                className={AuthStyle.mainForm}>
                <div className={AuthStyle.formContent}>
                    <div style={{ marginBottom: '40px' }} className={AuthStyle.headings}>
                        <h4 className={AuthStyle.welcome}>WELCOME!</h4>
                        <h1 className={AuthStyle.signIn}>Forgot password ?</h1>
                        <h5 className={AuthStyle.forgotCaption}>
                            Please enter your registered email address and we will send you a verification code for
                            password reset.
                        </h5>
                    </div>
                    <Form
                        name="otpForm"
                        labelCol={{ span: 12 }}
                        layout="vertical"
                        form={form}
                        wrapperCol={{ span: 22 }}
                        onFinish={sendCode}
                        autoComplete="off">
                        <Form.Item
                            label="Enter your email id"
                            name="email"
                            rules={[
                                { required: true, message: 'Please enter your email id' },
                                {
                                    pattern: new RegExp(
                                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    ),
                                    message: 'Please enter valid email id'
                                }
                            ]}>
                            <Input />
                        </Form.Item>

                        <Form.Item className={AuthStyle.button}>
                            <Button
                                loading={loading}
                                disabled={loading}
                                type="primary"
                                className={AuthStyle.btn}
                                htmlType="submit">
                                Continue
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Col>
        </>
    )
}
