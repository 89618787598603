import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Col, Button } from 'antd'
import AuthStyle from '../../../auth.module.scss'

SetNewPassword.propTypes = {
    newForm: PropTypes.object.isRequired,
    newPasswordSubmit: PropTypes.func.isRequired,
    passLoading: PropTypes.bool.isRequired
}
export default function SetNewPassword({ newForm, passLoading, newPasswordSubmit }) {
    return (
        <Col
            xl={{ span: 12, order: 1 }}
            lg={{ span: 12, order: 1 }}
            md={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
            xs={{ span: 24, order: 2 }}
            className={AuthStyle.mainForm}>
            <div className={AuthStyle.formContent}>
                <div className={AuthStyle.headings}>
                    <h4 className={AuthStyle.welcome}>WELCOME!</h4>
                    <h3 className={AuthStyle.pleaseEnterPass}>Please enter your new password.</h3>
                </div>

                <Form
                    name="new-password"
                    labelCol={{ span: 12 }}
                    layout="vertical"
                    form={newForm}
                    wrapperCol={{ span: 22 }}
                    onFinish={newPasswordSubmit}>
                    <Form.Item
                        name="code"
                        label="Enter verification code"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the verification code'
                            },
                            {
                                pattern: new RegExp(/^[0-9]{6}$/),
                                message: 'please enter valid verification code'
                            }
                        ]}>
                        <Input style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item
                        name="new_pass"
                        label="Enter your new password"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your password'
                            },
                            {
                                pattern: new RegExp(
                                    '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'
                                ),
                                message: 'Please enter strong password'
                            }
                        ]}>
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="cpass"
                        label="Confirm new password"
                        dependencies={['new_pass']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!'
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('new_pass') === value) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'))
                                }
                            })
                        ]}>
                        <Input.Password />
                    </Form.Item>

                    <Form.Item className={AuthStyle.button}>
                        <Button
                            disabled={passLoading}
                            loading={passLoading}
                            type="primary"
                            htmlType="submit"
                            className={AuthStyle.btn}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Col>
    )
}
