import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Auth } from 'aws-amplify'
import { message } from 'antd'
import useAPICall from '../../../hooks/useAPICall'
import { setLoginEmail } from '../../../redux/actions/signUp.action'
import { getQueueData } from '../../queue-admin/manage-queue-component/api-helper/api-helper'
import CombineLogin from './inner-comp/CombineLogin'

/**
 * login screen index
 * signIn -----> function for lo in user function
 * get ---->  method getting from custom hook useAPICall for get queueData api call
 * dispatch ----> passing through getQueueData function for set responsein redux store
 * navigate ----> after log in successull navigate to which user is log in
 * @param {*} setLoggedIn set state function for set value to be true
 * @returns jsx element of login screen
 */
export default function index({ setLoggedIn }) {
    const { get } = useAPICall()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [resetTrueOrFalse, setResetTrueFalse] = useState(false)
    const [userDetails, setUserDetails] = useState({})

    const signIn = async (values) => {
        try {
            setLoading(true)
            await Auth.signIn({
                username: values.username,
                password: values.password
            })
                .then((user) => {
                    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        setResetTrueFalse(true)
                        setUserDetails(user)
                        dispatch(setLoginEmail(values.username))
                    } else {
                        const idToken = user.signInUserSession.idToken
                        localStorage.setItem('userEmail', idToken.payload['email'])
                        sessionStorage.setItem('username', idToken.payload.sub)
                        if (idToken.payload['custom:role'] === 'queue_admin') {
                            getQueueData(get, dispatch)
                        }
                        message.success('login successful')
                        sessionStorage.setItem('userType', idToken.payload['custom:role'])
                        localStorage.setItem('userType', idToken.payload['custom:role'])
                        localStorage.setItem('branch_id', idToken.payload['custom:branch_id'])
                        localStorage.setItem('isPayment', false)
                        setLoggedIn(true)
                        setLoading(false)
                        navigate('/')
                    }
                })
                .catch((e) => {
                    throw e
                })
        } catch (error) {
            message.error(error.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <CombineLogin
                signIn={signIn}
                loading={loading}
                resetTrueOrFalse={resetTrueOrFalse}
                userDetails={userDetails}
                setLoggedIn={setLoggedIn}
            />
        </>
    )
}
