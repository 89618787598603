import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
// import { useNavigate } from 'react-router-dom'
import { Button } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import useAPICall from '../../../../../hooks/useAPICall'
import { paymentToken } from '../../api-helper/api-helper'
import SuperAdminStyle from '../../superAdmin.module.scss'

PackageInner.propTypes = {
    item: PropTypes.object.isRequired
}
/**
 * post ---> method from useAPICall
 * dispatch ---> get for set value in redux store
 * @param {*} item all package details
 * @returns JSX Element packageInner package card common
 */
export default function PackageInner({ item }) {
    const { post } = useAPICall()
    const dispatch = useDispatch()
    const [confirmloading, setConfirmLoading] = useState(false)
    return (
        <>
            <div
                className={
                    item.packageName === 'Platinum'
                        ? `${SuperAdminStyle.cardStyle} ${SuperAdminStyle.isPlatinium}`
                        : SuperAdminStyle.cardStyle
                }>
                <div className={SuperAdminStyle.headPart}>
                    <h3>{item.packageName}</h3>
                    {item.packageName === 'Platinum' ? (
                        <Button className={SuperAdminStyle.popularBtn}>Best seller</Button>
                    ) : null}
                </div>
                <div className={SuperAdminStyle.dollarOrMonth}>
                    <h1>
                        ${item.packageAmount}
                        {' /'}
                        {item.packagePeriod}
                    </h1>
                </div>

                <div className={SuperAdminStyle.paragraphDivMain}>
                    {item?.packageDescription?.[0].queues_allocation ? (
                        <div className={SuperAdminStyle.paragraphDiv}>
                            <CheckOutlined className={SuperAdminStyle.icon} />{' '}
                            <p>Max. {item?.packageDescription?.[0].queues_allocation + ' Queue creation'}</p>
                        </div>
                    ) : item?.packageDescription?.[0].queues_allocation == 0 ? (
                        item?.packageName == 'Platinum' ? (
                            <div className={SuperAdminStyle.paragraphDiv}>
                                <CheckOutlined className={SuperAdminStyle.icon} /> <p>Unlimited Queue creation</p>
                            </div>
                        ) : null
                    ) : null}
                    {item?.packageDescription?.[0].queue_users ? (
                        <div className={SuperAdminStyle.paragraphDiv}>
                            <CheckOutlined className={SuperAdminStyle.icon} />{' '}
                            <p>
                                {item?.packageDescription?.[0].queue_users.charAt(0).toUpperCase() +
                                    item?.packageDescription?.[0].queue_users.slice(1)}{' '}
                                queue users
                            </p>
                        </div>
                    ) : null}
                    {item?.packageDescription?.[0].access ? (
                        <div className={SuperAdminStyle.paragraphDiv}>
                            <CheckOutlined className={SuperAdminStyle.icon} />{' '}
                            <p>
                                {item?.packageDescription?.[0].access.charAt(0).toUpperCase() +
                                    item?.packageDescription?.[0].access.slice(1)}{' '}
                                access
                            </p>
                        </div>
                    ) : null}
                </div>
                <div className={SuperAdminStyle.buttonDiv}>
                    <Button
                        loading={confirmloading}
                        disabled={confirmloading}
                        onClick={() => {
                            // selectFunc(item)
                            paymentToken(item, post, setConfirmLoading, dispatch)
                        }}
                        type="primary">
                        {'Choose package'}
                    </Button>
                </div>
            </div>
        </>
    )
}
