import { BusinessQueAction, signUpActions } from './types'

/**
 * setQueFormDetails, setTrueFalseForParam, setWhichType, setParamData and similar
 * is all the funtion for using to set the value of reducer state
 * @param {*} payload is the what are the value sended to that function
 * @returns object with type of what type change and payload
 */

export function setQueFormDetails(payload) {
    return { type: BusinessQueAction.SET_ADD_QUEUE_FORM_DETAILS, payload }
}
export function setQAdminFormDetails(payload) {
    return { type: BusinessQueAction.SET_O_ADMIN_FORM_DETAILS, payload }
}
export function setTrueFalseForParam(payload) {
    return { type: BusinessQueAction.SET_PARAM_TRUE_FALSE, payload }
}
export function setWhichType(payload) {
    return { type: BusinessQueAction.SET_QUEUE_PARAM_OR_ACTION, payload }
}
export function setParamData(payload) {
    return { type: BusinessQueAction.SET_QUEUE_PARAM_ADD, payload }
}
export function setEditParamData(payload) {
    return { type: BusinessQueAction.SET_QUEUE_PARAM_EDIT, payload }
}
export function deleteParam(payload) {
    return { type: BusinessQueAction.SET_QUEUE_PARAM_DELETE, payload }
}
export function setActionData(payload) {
    return { type: BusinessQueAction.SET_QUEUE_ACTION_ADD, payload }
}
export function setEditActionData(payload) {
    return { type: BusinessQueAction.SET_QUEUE_ACTION_EDIT, payload }
}
export function deleteAction(payload) {
    return { type: BusinessQueAction.SET_QUEUE_ACTION_DELETE, payload }
}
export function setNotifyData(payload) {
    return { type: BusinessQueAction.SET_QUEUE_NOTIFICATION_ADD, payload }
}
export function setEditNotifyData(payload) {
    return { type: BusinessQueAction.SET_QUEUE_NOTIFICATION_EDIT, payload }
}
export function deleteNotify(payload) {
    return { type: BusinessQueAction.SET_QUEUE_NOTIFICATION_DELETE, payload }
}
export function setClearDetails() {
    return { type: BusinessQueAction.SET_CLEAR_QUEUE_FORM_DETAILS }
}
export function setQueueFormId(payload) {
    return { type: BusinessQueAction.SET_EDIT_ID_FORM, payload }
}
export function setAllQueues(payload) {
    return { type: BusinessQueAction.SET_ALL_QUEUE_DETAILS, payload }
}
export function setQueuePagination(payload) {
    return { type: BusinessQueAction.SET_QUEUE_PAGINATION, payload }
}
export function setBranchDetails(payload) {
    return { type: BusinessQueAction.SET_BRANCH_DETAILS, payload }
}

export function setNotEditId(payload) {
    return { type: BusinessQueAction.SET_NOT_EDIT_ID, payload }
}
export function setNotEditData(payload) {
    return { type: BusinessQueAction.SET_NOT_EDIT_DATA, payload }
}
export function setBusinessAdminStatus(payload) {
    return { type: BusinessQueAction.SET_BUSINESS_ADMIN_STATUS, payload }
}
export function setSubsWiseQueue(payload) {
    return { type: BusinessQueAction.SET_SUBSCRIPTION_WISE_QUEUE, payload }
}
export function setEditableQueue(payload) {
    return { type: BusinessQueAction.SET_EDITABLE_STATE, payload }
}
export function setSubscriptionDetails(payload) {
    return { type: BusinessQueAction.SET_SUBSCRIPTION_DETAILS, payload }
}
export function setPaymenntModal(payload) {
    return { type: BusinessQueAction.SET_PAYMENT_MODAL_STATE, payload }
}
export function setPaymenntDetails(payload) {
    return { type: BusinessQueAction.SET_PAYMENT_DETAILS_STATE, payload }
}
export function setPaymentToken(payload) {
    return { type: BusinessQueAction.SET_PAYMENT_TOKEN_STATE, payload }
}
export function setIsPaymentExp(payload) {
    return { type: BusinessQueAction.SET_ISPAYMENT_EXPIRE, payload }
}
export function setExpiresOrNot(payload) {
    return { type: BusinessQueAction.SET_EXPIRES_STATE, payload }
}
export function setBranchDefault() {
    return { type: signUpActions.SET_DEFAULT_STATE }
}
