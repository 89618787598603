import { queues, queueAdmin } from '../../../../utils/eps'
import { message } from 'antd'
import moment from 'moment'
import { setAllQueues, setExpiresOrNot, setIsPaymentExp } from '../../../../redux/actions/businessQue.action'

/* QUEUE API'S */
/**
 *
 * @param {*} get  get custom function used for API call
 * @param {*} page  pagination page number
 * @param {*} limit  pagination page limit
 * @param {*} search  search value
 * @returns suceess or error
 */
export async function viewAllQueues(get, page, limit, search) {
    try {
        const response = await get(queues.viewQueues(page, limit, search))
        return response
    } catch (err) {
        console.log('error in get all queues')
        throw err.message
    }
}

/**
 *
 * @param {*} post  post custom funtion used for API call
 * @param {*} values  data added to create a customer
 * @returns suceess or error
 */
export async function createQueueF(post, values) {
    try {
        const response = await post(queues.addQueues, values)
        return response
    } catch (error) {
        console.log(error, 'error in add queues')
        throw error.message
    }
}

/**
 *
 * @param {*} patch  patch custom funtion used for API call
 * @param {*} id  id of a customer to be edited
 * @param {*} values  data of a customer to be edited
 * @returns suceess or error
 */
export async function updateQueue(patch, id, values) {
    try {
        const response = await patch(queues.editQueue(id), values)
        return response
    } catch (error) {
        console.log(error, 'error in edit queue')
        throw error.message
    }
}

/**
 *
 * @param {*} get get custom function used for API call
 * @param {*} id id of a customer to fetch its data
 * @returns suceess or error
 */
export async function viewSingleQueue(get, id) {
    try {
        const response = await get(queues.viewSingle(id))
        return response
    } catch (err) {
        console.log(err, 'error in get single queue')
        throw err.message
    }
}

/**
 *
 * @param {*} del delete custom function used for API call
 * @param {*} id id of a customer to be deleted
 * @returns suceess or error
 */
export async function delQueue(del, id) {
    try {
        const response = await del(queues.deleteQueue(id))
        return response
    } catch (error) {
        console.log(error, 'error in delete single queue admin')
        throw error.message
    }
}

/* END QUEUE*/
/* QUEUE ADMINS API'S */
/**
 *
 * @param {*} get get custom function used for API call
 * @param {*} page  pagination page number
 * @param {*} limit  pagination page limit
 * @param {*} country_code country code value
 * @param {*} search search value
 * @returns success or error
 */
export async function viewAllQueueAdmins(get, page, limit, country_code, search) {
    try {
        const response = await get(queueAdmin.viewQueueAdmin(page, limit, country_code, search))
        return response
    } catch (err) {
        //  console.log(err, 'error in get all queues')
        throw err.message
    }
}

/**
 *
 * @param {*} get get custom function used for API call
 * @param {*} id id to fetch a single queue admin
 * @returns success or error
 */
export async function viewSingleQueueAdmin(get, id) {
    try {
        const response = await get(queueAdmin.singleQueueAdmin(id))
        return response
    } catch (err) {
        console.log(err, 'error in get single queue admin')
        throw err.message
    }
}

/**
 *
 * @param {*} del delete custon function used for API call
 * @param {*} id id to delete a single queue admin
 * @returns success or error
 */
export async function delQueueAdmin(del, id) {
    try {
        const response = await del(queueAdmin.deleteQueueAdmin(id))
        return response
    } catch (error) {
        console.log(error, 'error in delete single queue admin')
        throw error.message
    }
}

/**
 *
 * @param {*} post post custom function used for API call
 * @param {*} values data of a created queue admin
 * @returns success or error
 */
export async function createQueueAdminF(post, values) {
    try {
        const response = await post(queueAdmin.creteQueueAdmin, values)
        return response
    } catch (error) {
        console.log(error, 'error in add queue admin')
        throw error.message
    }
}

/**
 *
 * @param {*} patch patch custom function used for API call
 * @param {*} id id of a particular queue admin to be updated
 * @param {*} values updated data the queue admin
 * @returns
 */
export async function updateQueueAdmin(patch, id, values) {
    try {
        const response = await patch(queueAdmin.editQueueAdmin(id), values)
        return response
    } catch (error) {
        console.log(error, 'error in edit queue admin')
        throw error.message
    }
}
/* END QUEUE ADMINS*/

// COMMON Q_LIST API CALL

/**
 *
 * @param {*} get get custom function used for API call
 * @param {*} dispatch dispatch custom function used to set value
 * @param {*} page pagination page number
 * @param {*} limit pagination page limit
 */
export const getQueueBranchAdminData = async (get, dispatch, page, limit) => {
    try {
        const response = await viewAllQueues(get, page, limit)
        if (response.statusCode === 200 && response.message !== 'Unauthorized') {
            dispatch(setAllQueues(response))
        } else if (response.status === 401 && response.message === 'Unauthorized') {
            throw 'Request failed! your services temporarily off please contact admin'
        } else if (response.statusCode == 500) {
            throw response.message
        } else {
            throw response.message
        }
    } catch (error) {
        message.error(error)
    }
}

/**
 *
 * @param {*} dispatch dispatch custom function used to set value
 * @param {*} show boolean value for payment
 * @param {*} dueDate date
 * @returns function to check the subscription expiring
 */
export const checkSubExpiring = (dispatch, show, dueDate) => {
    let date = moment(dueDate)
    let today = moment()
    let newDate = date.diff(today, 'days')
    let oh = moment(dueDate).fromNow()
    if (newDate >= 0 && newDate <= 7 && moment(dueDate).fromNow().split(' ').pop() !== 'ago' && show === false) {
        dispatch(setIsPaymentExp(true))
        dispatch(setExpiresOrNot({ status: 'EXPIRE_SOON', details: oh }))
        localStorage.setItem('isPayment', true)
    } else if (moment(dueDate).fromNow().split(' ').pop() == 'ago' && show === false) {
        dispatch(setIsPaymentExp(true))
        dispatch(setExpiresOrNot({ status: 'IS_EXPIRED', details: oh }))
        localStorage.setItem('isPayment', true)
    } else {
        return
    }
}
