import React, { useState, useEffect } from 'react'
import { message } from 'antd'
import useAPICall from '../../../../../hooks/useAPICall'
import { allSubscriptions } from '../../api-helper/api-helper'
import SpinnerComp from '../../../spinner'
import Subscription from './Subscription'
import SuperAdminStyle from '../../superAdmin.module.scss'
/**
 * get ---> method from useAPICall
 * getSubscriptions ---> get subscription packages api
 * @returns jsx element subscription package page
 */
export default function index() {
    const { get } = useAPICall()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const getSubscriptions = async () => {
        setLoading(true)
        try {
            const response = await allSubscriptions(get)
            if (response.statusCode === 200) {
                setData(response?.data)
            } else {
                throw response.message
            }
        } catch (error) {
            message.error(error.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getSubscriptions()
    }, [])
    return (
        <div className={SuperAdminStyle.mainStyle}>
            {loading ? <SpinnerComp /> : <Subscription data={data} loading={loading} />}
        </div>
    )
}
