import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Auth } from 'aws-amplify'
import { Form, message } from 'antd'
import useAPICall from '../../../../../hooks/useAPICall'
import { setLoginEmail } from '../../../../../redux/actions/signUp.action'
import ForgotPassIndex from './ForgotPass'
import { userGet } from '../../api-helper'
export default function index() {
    const { loginGet } = useAPICall()
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [newPass, setNewPass] = useState(false)

    const sendCode = async (username) => {
        dispatch(setLoginEmail(username))
        try {
            setLoading(true)
            await Auth.forgotPassword(username)
            form.resetFields()
            setNewPass(true)
            setLoading(false)
            message.success('Verfication code sent to your email successfully')
        } catch (error) {
            message.error(error?.message ? error?.message : error)
        } finally {
            setLoading(false)
        }
    }

    const isUser = async (value) => {
        const username = value.email
        try {
            setLoading(true)
            const response = await userGet(loginGet, username)
            if (response.statusCode == 200 && response.userExist === true) {
                sendCode(username)
            } else if (response.statusCode == 200 && response.userExist === false) {
                form.setFields([
                    {
                        name: 'email', // required
                        value: username, //optional
                        errors: ['User not exist enter valid email id.']
                    }
                ])
                message.error('User not exist please enter valid register email id.')
            } else {
                response.message
            }
            setLoading(false)
        } catch (error) {
            message.error(error?.message ? error?.message : error)
        } finally {
            setLoading(false)
        }
    }
    return <ForgotPassIndex form={form} loading={loading} sendCode={isUser} newPass={newPass} setNewPass={setNewPass} />
}
